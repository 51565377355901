html {
  scroll-behavior: smooth;
}

/*=====***** PAGE CAREER *****====*/

.form_career_search {
  border: none;
  padding: 0;
}

.input_career_search {
  display: block;
  padding: 9px 4px 9px 40px;
  border: 1px solid #9da5b1;
  box-shadow: none;
  border-radius: 5px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.input_career_search:focus {
  box-shadow: none;
  border: 1px solid black;
}

.col-main-page .top-page-top-career {
  /* background-image: url(../assets/images/courier-services.jpg); */
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
}

.col-main-page .top-page-top-career.top-page-career {
  /* background-image: url(../assets/images/7.jpg); */
}

.col-main-page .top-page-top-career.top-page-career {
  /*height: 480px;*/
  height: 300px;
}

.col-main-page .top-page-top-career.top-page-career .page-bg-overlay {
  /*height: 480px;*/
  height: 300px;
}

.col-main-page .top-page-top-career.top-page-career {
  /*height: 450px;*/
  height: 300px;
}

.col-main-page .top-page-top-career.top-page-career .page-bg-overlay {
  /*height: 450px;*/
  height: 300px;
}

.col-main-page .top-page-top-career.top-page-career {
  /*height: 450px;*/
  height: 300px;
}

.col-main-page .top-page-top-career.top-page-career .page-bg-overlay {
  /*height: 450px;*/
  height: 300px;
}

.col-main-page .top-page-top-career.top-page-career {
  /*height: 470px;*/
  height: 300px;
}

.col-main-page .top-page-top-career.top-page-career .page-bg-overlay {
  /*height: 470px;*/
  height: 300px;
}

.col-main-page .top-page-top-career.top-page-career {
  /*height: 470px;*/
  height: 300px;
}

.col-main-page .top-page-top-career.top-page-career .page-bg-overlay {
  /*height: 470px;*/
  height: 300px;
}

.col-main-page .top-page-top-career.top-page-career {
  /*height: 450px;*/
  height: 300px;
}

.col-main-page .top-page-top-career.top-page-career .page-bg-overlay {
  /*height: 450px;*/
  height: 300px;
}

.col-main-page .top-page-top-career.top-page-career {
  /*height: 450px;*/
  height: 300px;
}

.col-main-page .top-page-top-career.top-page-career .page-bg-overlay {
  /*height: 450px;*/
  height: 300px;
}

/*=====***** PAGE COURIER SERVICE *****====*/

.col-main-page .top-page-courier-service {
  /* background-image: url(../assets/images/courier-services.jpg); */
  width: 100%;
  /*height: 500px;*/
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.col-main-page .top-page-courier-service.top-page-air-freight {
  /* background-image: url(../assets/images/2.jpg); */
}

.col-main-page .top-page-courier-service.top-page-cs-air-freight {
  /* background-image: url(../assets/images/2.jpg); */
}

.col-main-page .top-page-courier-service.top-page-sea-freight {
  /* background-image: url(../assets/images/3.jpg); */
}

.col-main-page .top-page-courier-service.top-page-land-truck {
  /* background-image: url(../assets/images/6.jpg); */
}

.col-main-page .top-page-courier-service.top-page-distribution-hub {
  /* background-image: url(../assets/images/4.jpg); */
}

.col-main-page
  .top-page-courier-service.top-page-international-freight-forwarding {
  /* background-image: url(../assets/images/9.jpg); */
}

.col-main-page .top-page-courier-service.top-page-delivery-cartage {
  /* background-image: url(../assets/images/11.jpg); */
}

.col-main-page .top-page-courier-service.top-page-custom-clearance {
  /* background-image: url(../assets/images/10.jpg); */
}

.col-main-page .top-page-courier-service.top-page-cold-storage-warehousing {
  /* background-image: url(../assets/images/12.jpg); */
}

.col-main-page .top-page-courier-service.top-page-ieps {
  /* background-image: url(../assets/images/5.jpg); */
}

.col-main-page .top-page-courier-service.top-page-company {
  /* background-image: url(../assets/images/7.jpg); */
}

.col-main-page .top-page-courier-service.top-page-supply-chain {
  /* background-image: url(../assets/images/13.jpg); */
}

.col-main-page .top-page-courier-service.top-page-contract-project-logistics {
  /* background-image: url(../assets/images/14.jpg); */
}

.col-main-page .top-page-courier-service.page-contact {
  /* background-image: url(../assets/images/7.jpg); */
  width: 100%;
  /*height: 500px;*/
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.page-bg-overlay {
  content: '';
  /* background: transparent url(../assets/images/pattern.png) repeat top left; */
  opacity: 0.72;
  background-image: linear-gradient(
    180deg,
    rgba(1, 46, 80, 0.8) 0,
    rgba(1, 46, 80, 0.48) 25%,
    rgba(1, 46, 80, 0.48) 45%,
    rgba(0, 0, 0, 0.8)
  );
  position: absolute;
  width: 100%;
  /*height: 500px;*/
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 0;
  list-style: none;
  padding-left: 0;
}

.col-main-page .top-page-courier-service.page-contact .page-bg-overlay {
  /*height: 500px;*/
  height: 100vh;
}

.col-main-page .top-page-courier-service .col-in-area {
  /*padding: 140px 0px 0px 0px;*/
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.col-main-page .top-page-courier-service .col-in-area .breadcrumb {
  position: relative;
  z-index: 1;
  color: #ffffff;
  opacity: 0.9;
  margin-bottom: 15px;
}

.col-main-page .top-page-detail .col-in-area .breadcrumb {
  position: relative;
  z-index: 1;
  color: black;
  opacity: 0.9;
  margin-bottom: 15px;
}

.col-main-page .top-page-courier-service .col-in-area .breadcrumb li {
  font-weight: 500;
  padding-right: 5px;
  letter-spacing: 0.42px;
  /* text-transform: uppercase; */
  font-size: 12px;
}

.col-main-page .top-page-detail .col-in-area .breadcrumb li {
  font-weight: 500;
  padding-right: 5px;
  letter-spacing: 0.42px;
  /* text-transform: uppercase; */
  font-size: 12px;
}

.col-main-page
  .top-page-courier-service
  .col-in-area
  .breadcrumb
  > li
  + li::before {
  padding: 0 5px;
  color: #fff;
  opacity: 0.9;
  content: '\203A';
}

.col-main-page .top-page-detail .col-in-area .breadcrumb > li + li::before {
  padding: 0 5px;
  color: black;
  opacity: 0.9;
  content: '\203A';
}

.col-main-page .top-page-courier-service .col-in-area .breadcrumb li a {
  color: #fff;
  opacity: 0.9;
  /* text-transform: uppercase; */
  transition: 0.5s ease;
}

.col-main-page .top-page-detail .col-in-area .breadcrumb li a {
  color: black;
  opacity: 0.9;
  /* text-transform: uppercase; */
  transition: 0.5s ease;
}

.col-main-page .top-page-courier-service .col-in-area .breadcrumb li a:hover,
.col-main-page .top-page-courier-service .col-in-area .breadcrumb li a:focus,
.col-main-page .top-page-courier-service .col-in-area .breadcrumb li a:active {
  opacity: 1;
  transition: 0.5s ease;
}

.col-main-page .top-page-courier-service .col-in-area .title {
  color: #fff;
  position: relative;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 0.42px;
  margin-bottom: 15px;
}

.col-main-page .top-page-courier-service .col-in-area .subtitle {
  color: #fff;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.42px;
  margin-bottom: 15px;
  line-height: 26px;
}

.col-main-page .top-page-courier-service .col-in-area .col-btn-talk {
  margin-top: 30px;
  position: relative;
}

.col-main-page .top-page-courier-service .col-in-area .col-btn-talk .btn-talk {
  background: rgba(2, 46, 80, 0.52);
  border: 2px solid #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5px 15px;
  height: 50px;
  margin: 0px 5px 0px 0px;
  width: 100%;
  max-width: 200px;
  transition: 0.5s ease;
}

.col-main-page
  .top-page-courier-service
  .col-in-area
  .col-btn-talk
  .btn-talk.bt-learn-more {
  background: rgba(2, 46, 80, 0.52);
  border: 2px solid #ffffff;
  transition: 0.5s ease;
}

.col-main-page
  .top-page-courier-service
  .col-in-area
  .col-btn-talk
  .btn-talk:hover,
.col-main-page
  .top-page-courier-service
  .col-in-area
  .col-btn-talk
  .btn-talk:focus,
.col-main-page
  .top-page-courier-service
  .col-in-area
  .col-btn-talk
  .btn-talk:active {
  transition: 0.5s ease;
  opacity: 0.72;
}

.col-main-page
  .top-page-courier-service
  .col-in-area
  .col-btn-talk
  .btn-talk
  img {
  width: 100%;
  max-width: 20px;
  margin-right: 5px;
}

.col-main-page
  .top-page-courier-service
  .col-in-area
  .col-btn-talk
  .btn-talk
  span {
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.42px;
  font-family: 'IBM Plex Sans', sans-serif;
  transition: transform 0.5s ease;
  position: relative;
  top: 2px;
}

.content {
  padding-top: 60px;
  position: relative;
}

.content img {
  width: 100%;
  border-radius: 10px;
}

.content h1.title {
  color: #022e50;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 0.42px;
  margin-bottom: 15px;
}

.content h3.title {
  color: #022e50;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 0.42px;
  margin-bottom: 15px;
}

.content h4.subtitle {
  color: #022e50;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.42px;
  margin-bottom: 15px;
}

.content p {
  color: #6e7888;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.42px;
  margin-bottom: 15px;
  line-height: 1.6;
}

.content .col-our-office-location h4 {
  color: #022e50;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.42px;
  margin-bottom: 15px;
}

.content .col-our-office-location p {
  color: #6e7888;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.42px;
  margin-bottom: 15px;
  line-height: 1.6;
}

.content .col-our-office-location iframe {
  border-radius: 10px;
  height: 300px;
}

.content .progress {
  background: #02bee8;
  border-radius: 0px;
  height: 10px;
}

.content .progress .progress-bar {
  background: #022e50;
}

.content .thumbnail {
  border: 1px #dcf2ff solid;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-radius: 15px;
  height: auto;
  max-height: 480px;
  min-height: 480px;
  margin-top: 25px;
  transition: 0.5s ease;
}

.content a.link-thumbnail:hover .thumbnail,
.content a.link-thumbnail:focus .thumbnail,
.content a.link-thumbnail:active .thumbnail {
  box-shadow: 0px 8px 16px 0px rgba(2, 46, 80, 0.2);
  transition: 0.5s ease;
}

.content .thumbnail img {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-bottom: 0px;
}

.content .thumbnail .caption {
  padding: 30px;
  height: 245px;
  overflow: scroll;
}

.content .thumbnail .caption h3 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #022e50;
}

.content .thumbnail .caption p {
  font-size: 14px;
  font-weight: 500;
}

.content .col-our-client {
  text-align: center;
  margin-top: 45px;
}

.content .col-our-client img {
  width: 100%;
  max-width: 150px;
  border-radius: 0px;
}

.content .col-talk {
  background: #022e50;
  padding: 45px 0px;
}

.content .col-talk h4 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.42px;
  line-height: 1.8;
  margin-bottom: 0px;
  position: relative;
  top: 10px;
}

.content .col-talk.col-talk-iff h4 {
  position: relative;
  top: 0px;
}

.content .col-talk .col-btn-talk {
  margin-top: 0px;
}

.content .col-talk.col-talk-iff .col-btn-talk {
  margin-top: 0px;
}

.content .col-talk .col-btn-talk .btn-talk {
  background: rgba(2, 46, 80, 0.52);
  border: 2px solid #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5px 15px;
  height: 50px;
  margin: 0px 15px;
  width: 100%;
  max-width: 200px;
  transition: 0.5s ease;
}

.content .col-talk .col-btn-talk .btn-talk:hover,
.content .col-talk .col-btn-talk .btn-talk:focus,
.content .col-talk .col-btn-talk .btn-talk:active {
  transition: 0.5s ease;
  opacity: 0.72;
}

.content .col-talk .col-btn-talk .btn-talk img {
  width: 100%;
  max-width: 20px;
  margin-right: 5px;
}

.content .col-talk .col-btn-talk .btn-talk span {
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.42px;
  font-family: 'IBM Plex Sans', sans-serif;
  transition: transform 0.5s ease;
  position: relative;
  top: 2px;
}

/*=====***** PAGE CONTACT *****=====*/

.content .form-contact {
  margin-top: 45px;
  margin-bottom: 90px;
}

.content .form-contact form .form-group {
  margin-bottom: 30px;
}

.content .form-contact form .form-group label {
  font-size: 14px;
  font-weight: 600;
  color: #6e7888;
  margin-bottom: 10px;
}

.content .form-contact form .form-group label span {
  color: #f46388;
}

.content .form-contact form .form-group .form-control {
  font-size: 14px;
  font-weight: 600;
  color: #102d4e;
  height: 40px;
}

.content .form-contact form .form-group .form-control:focus {
  box-shadow: none;
  border: 1px #02bee8 solid;
}

.content .form-contact form .form-group textarea.form-control {
  height: auto;
}

.content .form-contact form .alert {
  padding: 15px;
  font-size: 14px;
  color: #03bfe8;
  font-weight: 600;
  text-align: center;
  background: none;
  border: none;
}

.content .form-contact form .alert .close {
  color: #102d4e;
  float: right;
  box-shadow: none;
  border: none;
  background: none;
  font-size: 20px;
  font-weight: 600;
  padding: 0px;
  position: relative;
  top: -6px;
}

.content .form-contact form .btn.btn-send-message {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  background: #03bfe8;
  border: 1px #03bfe8 solid;
  height: 50px;
  color: #fff;
  margin-bottom: 15px;
  transition: 0.5s ease;
}

.content .form-contact form .btn.btn-send-message:hover,
.content .form-contact form .btn.btn-send-message:focus,
.content .form-contact form .btn.btn-send-message:active {
  opacity: 0.72;
  transition: 0.5s ease;
}

.position-fixed-whatsapp {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 999;
}

.position-fixed-whatsapp .btn-talk {
  background: rgba(2, 46, 80, 1);
  border: 2px solid #ffffff;
  border-right: 0px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px 0px 0px 10px;
  padding: 10px 15px 5px 15px;
  height: 50px;
  margin: 0px;
  width: 100%;
  transition: 0.5s ease;
  height: 100%;
  max-height: 90px;
  min-height: 90px;
}

.position-fixed-whatsapp .btn-talk:hover,
.position-fixed-whatsapp .btn-talk:focus,
.position-fixed-whatsapp .btn-talk:active {
  transition: 0.5s ease;
  opacity: 0.72;
}

.position-fixed-whatsapp .btn-talk img {
  width: 100%;
  max-width: 26px;
  margin-right: 0px;
}

.col-content-office-location {
  margin-bottom: 0px;
}

/*=====***** TABS WHY CHOOSE US *****=====*/

.content .col-tabs-why-choose-us {
  margin-top: 15px;
}

.col-tab-responsive-mobile-wcu {
  margin-top: 30px;
  display: none;
}

/*=====***** PAGE OFFICE LOCATION *****=====*/

.content .col-content-office-location iframe {
  border-radius: 10px;
}

.content .col-tabs-why-choose-us .nav {
  width: 100%;
  max-width: 300px;
  min-width: 300px;
}

.content .col-tabs-why-choose-us .nav .nav-link {
  border: 1px #dcf2ff solid;
  border-radius: 10px;
  text-align: left;
  height: 50px;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #022e50;
  padding-left: 20px;
  padding-right: 20px;
}

.content .col-tabs-why-choose-us .nav .nav-link.active {
  background: #02bee8;
  border: 1px #02bee8 solid;
  color: #fff;
}

.content .col-tabs-why-choose-us .tab-content {
  border: 1px #dcf2ff solid;
  border-radius: 10px;
  padding: 30px;
  background: #f5faff;
}

.content .col-tabs-why-choose-us .tab-content .tab-pane img {
  border-radius: 10px;
  margin-bottom: 30px;
}

.content .col-tabs-why-choose-us .tab-content .tab-pane h3.title-content-tabs {
  color: #022e50;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.42px;
  margin-bottom: 15px;
}

.content .col-tabs-why-choose-us .tab-content .tab-pane p.text-content-tabs {
  color: #6e7888;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0.42px;
  margin-bottom: 15px;
}

.content .col-bottom-nav-tabs .col-thumbnail {
  margin-top: 15px;
}

.content .col-bottom-nav-tabs .col-thumbnail .box-img {
  background: #022e50;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 15px;
  margin-bottom: 15px;
}

.content .col-bottom-nav-tabs .col-thumbnail .box-img img {
  border-radius: 0px;
  position: relative;
  top: 10px;
}

.content .col-bottom-nav-tabs .col-thumbnail .caption h3 {
  color: #022e50;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.42px;
  margin-bottom: 15px;
}

/*=====***** FOOTER *****=====*/

footer {
  border-top: 1px #022e50 solid;
}

footer .top-footer {
  border-bottom: 1px #022e50 solid;
  padding: 45px 0px;
}

footer .top-footer img.logo-ckl {
  width: 100%;
  max-width: 120px;
}

footer .top-footer h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #022e50;
}

footer .top-footer p {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

footer .top-footer p a {
  color: #6e7888;
  transition: 0.5s ease;
}

footer .top-footer p a:hover,
footer .top-footer p a:focus,
footer .top-footer p a:active {
  color: #02bee8;
  transition: 0.5s ease;
}

footer .bottom-footer {
  padding: 30px 0px;
}

footer .bottom-footer p {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #022e50;
}

footer .bottom-footer .col-medsos {
  text-align: right;
}

footer .bottom-footer .col-medsos p a:hover,
footer .bottom-footer .col-medsos p a:focus,
footer .bottom-footer .col-medsos p a:active {
  opacity: 0.72;
  transition: 0.5s ease;
}

/*=====***** CODROPS TOP FOR PAGE DETAIL *****=====*/

#collapse-top-index {
  position: relative;
}

#collapse-top-index.show {
  position: relative;
  height: 100vh;
  -moz-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s;
}

#collapse-top-index .card.card-body {
  position: relative;
  height: 100vh;
  padding: 0px;
}

.codrops-top.codrops-top-for-page-detail {
  position: fixed;
  top: 0;
}

.col-main-page.col-main-page-detail {
  position: relative;
  background: #ffffff;
  transition: all 1s ease-out;
  z-index: 9;
}

.codrops-top-for-page-detail {
  background-color: transparent;
  transition: all 0.1s ease-in-out;
}

.codrops-top-for-page-detail.scrolled {
  background-color: rgba(2, 46, 80, 1);
  transition: all 1s ease-out;
}

#collapse-top-index.show .card.card-body .slider-homepage .cb-slideshow {
  display: contents;
}

#collapse-top-index.show .card.card-body .slider-homepage {
  z-index: 0;
}

footer {
  position: relative;
  background: #ffffff;
  z-index: 9;
}

.col-main-page .top-page-courier-service.top-page-sea-freight {
  /*height: 530px;*/
  height: 100vh;
}

.col-main-page .top-page-courier-service.top-page-sea-freight .page-bg-overlay {
  /*height: 530px;*/
  height: 100vh;
}

.col-main-page .top-page-courier-service.top-page-sea-freight.tpsf {
  /*height: 530px;*/
  height: 100vh;
}

.col-main-page
  .top-page-courier-service.top-page-sea-freight.tpsf
  .page-bg-overlay {
  /*height: 530px;*/
  height: 100vh;
}

.col-main-page .top-page-courier-service.top-page-contract-project-logistics {
  /*height: 560px;*/
  height: 100vh;
}

.col-main-page
  .top-page-courier-service.top-page-contract-project-logistics
  .page-bg-overlay {
  /*height: 560px;*/
  height: 100vh;
}

.col-main-page .top-page-courier-service.top-page-land-truck {
  /*height: 530px;*/
  height: 100vh;
}

.col-main-page .top-page-courier-service.top-page-land-truck .page-bg-overlay {
  /*height: 530px;*/
  height: 100vh;
}

.content .col-our-office-location #map {
  border-radius: 10px;
}

.content .col-content-office-location #map {
  border-radius: 10px;
}

@media (min-width: 360px) and (max-width: 479px) {
  .col-main-page .top-page-courier-service {
    /*height: 560px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-cs-air-freight {
    /*height: 530px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-land-truck {
    /*height: 630px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-distribution-hub {
    /*height: 560px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-international-freight-forwarding {
    /*height: 620px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-sea-freight {
    /*height: 660px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-delivery-cartage {
    /*height: 490px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-sea-freight.tpsf {
    /*height: 680px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-air-freight.tpaf {
    /*height: 550px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-cold-storage-warehousing {
    /*height: 520px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-custom-clearance {
    /*height: 550px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-ieps {
    /*height: 620px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-contract-project-logistics {
    /*height: 760px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-office-location {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.page-contact {
    /*height: 450px;*/
    height: 100vh;
  }

  .top-page-courier-service.page-contact.top-page-office-location {
    /*height: 450px;*/
    height: 100vh;
  }

  .page-bg-overlay {
    /*height: 560px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-cs-air-freight
    .page-bg-overlay {
    /*height: 530px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-land-truck
    .page-bg-overlay {
    /*height: 630px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-distribution-hub
    .page-bg-overlay {
    /*height: 560px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-international-freight-forwarding
    .page-bg-overlay {
    /*height: 620px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-air-freight.tpaf
    .page-bg-overlay {
    /*height: 550px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-custom-clearance
    .page-bg-overlay {
    /*height: 550px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-delivery-cartage
    .page-bg-overlay {
    /*height: 490px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-sea-freight
    .page-bg-overlay {
    /*height: 660px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-sea-freight.tpsf
    .page-bg-overlay {
    /*height: 680px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-cold-storage-warehousing
    .page-bg-overlay {
    /*height: 520px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-contract-project-logistics
    .page-bg-overlay {
    /*height: 760px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-ieps .page-bg-overlay {
    /*height: 620px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-office-location
    .page-bg-overlay {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.page-contact .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-company {
    /*height: 480px;*/
    height: 100vh;
  }

  .top-page-courier-service.page-contact.top-page-office-location
    .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-company .page-bg-overlay {
    /*height: 480px;*/
    height: 100vh;
  }

  .content {
    padding-top: 60px;
  }

  .col-main-page .top-page-courier-service .col-in-area {
    padding: 0px 0px 0px 0px;
  }

  .content .col-tabs-why-choose-us .d-flex.align-items-start {
    display: block !important;
  }

  .content .col-tabs-why-choose-us .nav {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-right: 0px !important;
    margin-bottom: 30px;
  }

  .content .thumbnail {
    height: auto;
    max-height: 500px;
    min-height: 500px;
  }

  .content .thumbnail .caption {
    padding: 30px;
    height: 270px;
    overflow: scroll;
  }

  .content .col-talk .col-btn-talk .btn-talk {
    margin: 0px;
  }

  .content .col-talk .col-btn-talk .btn-talk span {
    font-size: 14px;
    top: 0px;
  }

  .content .col-talk h4 {
    top: 0px;
  }

  .content .col-talk .col-btn-talk {
    margin-top: 15px !important;
    margin-bottom: 15px;
  }

  .content .col-our-client {
    margin-top: 15px;
  }

  .content .col-our-client img {
    margin: 10px auto;
  }

  .content .col-tabs-why-choose-us {
    display: none;
  }

  .col-tab-responsive-mobile-wcu {
    margin-top: 15px;
    display: block;
  }

  .col-tab-responsive-mobile-wcu .accordion .accordion-item {
    margin-top: 15px;
    border: 0px #dcf2ff solid;
    border-radius: 10px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    box-shadow: none;
    background: #02bee8;
    border: 1px #02bee8 solid;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button.collapsed {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #022e50;
    box-shadow: none;
    background: #ffffff;
    border: 1px #dcf2ff solid;
  }

  .col-tab-responsive-mobile-wcu .accordion .accordion-item .accordion-body {
    padding: 30px 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    img {
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    h1.title-content-tabs {
    color: #022e50;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.42px;
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    h3.title-content-tabs {
    color: #022e50;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.42px;
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    p.text-content-tabs {
    color: #6e7888;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 0.42px;
    margin-bottom: 0px;
  }

  footer .top-footer img.logo-ckl {
    margin-bottom: 0px;
  }

  footer .top-footer h4 {
    margin-bottom: 15px;
    margin-top: 30px;
  }

  footer .bottom-footer .col-medsos {
    text-align: left;
    margin-top: 30px;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .content {
    padding-top: 60px;
  }

  .col-main-page .top-page-courier-service .col-in-area {
    padding: 0px 0px 0px 0px;
  }

  .content .col-tabs-why-choose-us .d-flex.align-items-start {
    display: block !important;
  }

  .content .col-tabs-why-choose-us .nav {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-right: 0px !important;
    margin-bottom: 30px;
  }

  .content .thumbnail {
    height: auto;
    max-height: 420px;
    min-height: 420px;
  }

  .content .thumbnail .caption {
    padding: 30px;
    height: 180px;
    overflow: scroll;
  }

  .content .col-talk .col-btn-talk .btn-talk {
    margin: 0px;
  }

  .content .col-talk .col-btn-talk .btn-talk span {
    font-size: 14px;
    top: 0px;
  }

  .content .col-our-client {
    margin-top: 15px;
  }

  .content .col-our-client img {
    margin: 10px auto;
  }

  .content .col-talk h4 {
    top: 0px;
  }

  .content .col-talk .col-btn-talk {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .content .col-talk.col-talk-iff .col-btn-talk {
    margin-top: 15px;
  }

  .col-main-page
    .top-page-courier-service.top-page-international-freight-forwarding {
    /*height: 540px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-international-freight-forwarding
    .page-bg-overlay {
    /*height: 540px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-air-freight.tpaf {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-air-freight.tpaf
    .page-bg-overlay {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-sea-freight {
    /*height: 550px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-sea-freight
    .page-bg-overlay {
    /*height: 550px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-sea-freight.tpsf {
    /*height: 570px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-sea-freight.tpsf
    .page-bg-overlay {
    /*height: 570px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-delivery-cartage {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-delivery-cartage
    .page-bg-overlay {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-custom-clearance {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-custom-clearance
    .page-bg-overlay {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-cold-storage-warehousing {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-cold-storage-warehousing
    .page-bg-overlay {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-ieps {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-ieps .page-bg-overlay {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.page-contact {
    /*height: 420px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-office-location {
    /*height: 420px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.page-contact .page-bg-overlay {
    /*height: 420px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-office-location
    .page-bg-overlay {
    /*height: 420px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-contract-project-logistics {
    /*height: 620px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-contract-project-logistics
    .page-bg-overlay {
    /*height: 620px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-company {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-company .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-land-truck {
    /*height: 520px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-land-truck
    .page-bg-overlay {
    /*height: 520px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-cs-air-freight {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-cs-air-freight
    .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-distribution-hub {
    /*height: 490px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-distribution-hub
    .page-bg-overlay {
    /*height: 490px;*/
    height: 100vh;
  }

  .content .col-tabs-why-choose-us {
    display: none;
  }

  .col-tab-responsive-mobile-wcu {
    margin-top: 15px;
    display: block;
  }

  .col-tab-responsive-mobile-wcu .accordion .accordion-item {
    margin-top: 15px;
    border: 0px #dcf2ff solid;
    border-radius: 10px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    box-shadow: none;
    background: #02bee8;
    border: 1px #02bee8 solid;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button.collapsed {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #022e50;
    box-shadow: none;
    background: #ffffff;
    border: 1px #dcf2ff solid;
  }

  .col-tab-responsive-mobile-wcu .accordion .accordion-item .accordion-body {
    padding: 30px 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    img {
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    h1.title-content-tabs {
    color: #022e50;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.42px;
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    h3.title-content-tabs {
    color: #022e50;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.42px;
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    p.text-content-tabs {
    color: #6e7888;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 0.42px;
    margin-bottom: 0px;
  }

  footer .top-footer img.logo-ckl {
    margin-bottom: 0px;
  }

  footer .top-footer h4 {
    margin-bottom: 15px;
    margin-top: 30px;
  }

  footer .bottom-footer .col-medsos {
    text-align: left;
    margin-top: 30px;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .content {
    padding-top: 60px;
  }

  .col-main-page .top-page-courier-service .col-in-area {
    padding: 0px 0px 0px 0px;
  }

  .content .col-tabs-why-choose-us .d-flex.align-items-start {
    display: block !important;
  }

  .content .col-tabs-why-choose-us .nav {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-right: 0px !important;
    margin-bottom: 30px;
  }

  .content .thumbnail {
    height: auto;
    max-height: 435px;
    min-height: 435px;
  }

  .content .thumbnail .caption {
    padding: 30px;
    height: 205px;
    overflow: scroll;
  }

  .content .col-our-client {
    margin-top: 15px;
  }

  .content .col-our-client img {
    margin: 10px auto;
  }

  .content .col-talk h4 {
    top: 0px;
  }

  .content .col-talk .col-btn-talk {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .content .col-talk.col-talk-iff .col-btn-talk {
    margin-top: 15px;
  }

  .content .col-talk .col-btn-talk .btn-talk {
    margin: 0px;
  }

  .content .col-talk .col-btn-talk .btn-talk span {
    font-size: 14px;
    top: 0px;
  }

  .col-main-page .top-page-courier-service.top-page-air-freight.tpaf {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-air-freight.tpaf
    .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-sea-freight.tpsf {
    /*height: 520px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-sea-freight.tpsf
    .page-bg-overlay {
    /*height: 520px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-delivery-cartage {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-delivery-cartage
    .page-bg-overlay {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-custom-clearance {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-custom-clearance
    .page-bg-overlay {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-cold-storage-warehousing {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-cold-storage-warehousing
    .page-bg-overlay {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-ieps {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-ieps .page-bg-overlay {
    /*height: 470px;*/
    height: 100vh;
  }

  .top-page-courier-service.page-contact {
    /*height: 400px;*/
    height: 100vh;
  }

  .top-page-courier-service.page-contact .page-bg-overlay {
    /*height: 400px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.page-contact {
    /*height: 390px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.page-contact .page-bg-overlay {
    /*height: 390px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.page-contact.top-page-office-location {
    /*height: 390px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.page-contact.top-page-office-location
    .page-bg-overlay {
    /*height: 390px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-distribution-hub {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-distribution-hub
    .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-contract-project-logistics {
    /*height: 580px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-contract-project-logistics
    .page-bg-overlay {
    /*height: 580px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-company {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-company .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-sea-freight {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-sea-freight
    .page-bg-overlay {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-cs-air-freight {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-cs-air-freight
    .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-land-truck {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-land-truck
    .page-bg-overlay {
    /*height: 510px;*/
    height: 100vh;
  }

  .content .col-tabs-why-choose-us {
    display: none;
  }

  .col-tab-responsive-mobile-wcu {
    margin-top: 15px;
    display: block;
  }

  .col-tab-responsive-mobile-wcu .accordion .accordion-item {
    margin-top: 15px;
    border: 0px #dcf2ff solid;
    border-radius: 10px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    box-shadow: none;
    background: #02bee8;
    border: 1px #02bee8 solid;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button.collapsed {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #022e50;
    box-shadow: none;
    background: #ffffff;
    border: 1px #dcf2ff solid;
  }

  .col-tab-responsive-mobile-wcu .accordion .accordion-item .accordion-body {
    padding: 30px 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    img {
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    h1.title-content-tabs {
    color: #022e50;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.42px;
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    h3.title-content-tabs {
    color: #022e50;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.42px;
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    p.text-content-tabs {
    color: #6e7888;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 0.42px;
    margin-bottom: 0px;
  }

  footer .top-footer img.logo-ckl {
    margin-bottom: 0px;
  }

  footer .top-footer h4 {
    margin-bottom: 15px;
    margin-top: 30px;
  }

  footer .bottom-footer .col-medsos {
    text-align: left;
    margin-top: 30px;
  }
}

@media (min-width: 768px) and (max-width: 851px) {
  .content {
    padding-top: 60px;
  }

  .col-main-page .top-page-courier-service .col-in-area {
    padding: 0px 0px 0px 0px;
  }

  .content .thumbnail {
    height: auto;
    max-height: 420px;
    min-height: 420px;
  }

  .content .thumbnail .caption {
    padding: 30px;
    height: 180px;
    overflow: scroll;
  }

  .content .col-talk .col-btn-talk .btn-talk {
    margin: 0px;
  }

  .content .col-talk .col-btn-talk .btn-talk img {
    display: none;
  }

  .content .col-talk .col-btn-talk .btn-talk span {
    font-size: 13px;
    top: 0px;
  }

  .content .col-talk h4 {
    top: 10px;
  }

  .col-main-page .top-page-courier-service.page-contact {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.page-contact .page-bg-overlay {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.page-contact.top-page-office-location {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.page-contact.top-page-office-location
    .page-bg-overlay {
    /*height: 440px;*/
    height: 100vh;
  }

  footer .top-footer h4 {
    margin-bottom: 15px;
    margin-top: 0px;
    min-height: 40px;
  }

  footer .bottom-footer .col-medsos {
    text-align: right;
    margin-top: 0px;
  }

  .col-main-page .top-page-courier-service.top-page-sea-freight.tpsf {
    /*height: 540px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-sea-freight.tpsf
    .page-bg-overlay {
    /*height: 540px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-delivery-cartage {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-delivery-cartage
    .page-bg-overlay {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-cold-storage-warehousing {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-cold-storage-warehousing
    .page-bg-overlay {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-contract-project-logistics {
    /*height: 570px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-contract-project-logistics
    .page-bg-overlay {
    /*height: 570px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-sea-freight {
    /*height: 530px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-sea-freight
    .page-bg-overlay {
    /*height: 530px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-cs-air-freight {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-cs-air-freight
    .page-bg-overlay {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-land-truck {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-land-truck
    .page-bg-overlay {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-distribution-hub {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-distribution-hub
    .page-bg-overlay {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-air-freight {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-air-freight
    .page-bg-overlay {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-custom-clearance {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-custom-clearance
    .page-bg-overlay {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-ieps {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-ieps .page-bg-overlay {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-company {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-company .page-bg-overlay {
    /*height: 470px;*/
    height: 100vh;
  }

  .content .col-talk .col-btn-talk {
    margin-top: 0px;
  }

  .content .col-tabs-why-choose-us {
    display: none;
  }

  .col-tab-responsive-mobile-wcu {
    margin-top: 15px;
    display: block;
  }

  .col-tab-responsive-mobile-wcu .accordion .accordion-item {
    margin-top: 15px;
    border: 0px #dcf2ff solid;
    border-radius: 10px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    box-shadow: none;
    background: #02bee8;
    border: 1px #02bee8 solid;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button.collapsed {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #022e50;
    box-shadow: none;
    background: #ffffff;
    border: 1px #dcf2ff solid;
  }

  .col-tab-responsive-mobile-wcu .accordion .accordion-item .accordion-body {
    padding: 30px 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    img {
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    h1.title-content-tabs {
    color: #022e50;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.42px;
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    h3.title-content-tabs {
    color: #022e50;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.42px;
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    p.text-content-tabs {
    color: #6e7888;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 0.42px;
    margin-bottom: 0px;
  }
}

@media (min-width: 852px) and (max-width: 991px) {
  .content {
    padding-top: 60px;
  }

  .col-main-page .top-page-courier-service .col-in-area {
    padding: 0px 0px 0px 0px;
  }

  .content .thumbnail {
    height: auto;
    max-height: 420px;
    min-height: 420px;
  }

  .content .thumbnail .caption {
    padding: 30px;
    height: 180px;
    overflow: scroll;
  }

  .content .col-talk .col-btn-talk .btn-talk {
    margin: 0px;
  }

  .content .col-talk .col-btn-talk .btn-talk img {
    display: none;
  }

  .content .col-talk .col-btn-talk .btn-talk span {
    font-size: 14px;
    top: 0px;
  }

  .content .col-talk h4 {
    top: 0px;
  }

  footer .top-footer h4 {
    margin-bottom: 15px;
    margin-top: 0px;
    min-height: 40px;
  }

  footer .bottom-footer .col-medsos {
    text-align: right;
    margin-top: 0px;
  }

  .col-main-page .top-page-courier-service.top-page-sea-freight.tpsf {
    /*height: 540px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-sea-freight.tpsf
    .page-bg-overlay {
    /*height: 540px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-delivery-cartage {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-delivery-cartage
    .page-bg-overlay {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-cold-storage-warehousing {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-cold-storage-warehousing
    .page-bg-overlay {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-contract-project-logistics {
    /*height: 570px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-contract-project-logistics
    .page-bg-overlay {
    /*height: 570px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.page-contact {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-office-location {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.page-contact .page-bg-overlay {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-office-location
    .page-bg-overlay {
    /*height: 440px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-sea-freight {
    /*height: 530px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-sea-freight
    .page-bg-overlay {
    /*height: 530px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-cs-air-freight {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-cs-air-freight
    .page-bg-overlay {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-distribution-hub {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-distribution-hub
    .page-bg-overlay {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-land-truck {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-land-truck
    .page-bg-overlay {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-air-freight {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-air-freight
    .page-bg-overlay {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-custom-clearance {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-custom-clearance
    .page-bg-overlay {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-ieps {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-ieps .page-bg-overlay {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-company {
    /*height: 470px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-company .page-bg-overlay {
    /*height: 470px;*/
    height: 100vh;
  }

  .content .col-talk h4 {
    top: 10px;
  }

  .content .col-talk .col-btn-talk {
    margin-top: 0px;
  }

  .content .col-talk .col-btn-talk .btn-talk {
    margin: 0px;
  }

  .content .col-talk .col-btn-talk .btn-talk img {
    display: none;
  }

  .content .col-talk .col-btn-talk .btn-talk span {
    font-size: 13px;
    top: 0px;
  }

  .content .col-tabs-why-choose-us {
    display: none;
  }

  .col-tab-responsive-mobile-wcu {
    margin-top: 15px;
    display: block;
  }

  .col-tab-responsive-mobile-wcu .accordion .accordion-item {
    margin-top: 15px;
    border: 0px #dcf2ff solid;
    border-radius: 10px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    box-shadow: none;
    background: #02bee8;
    border: 1px #02bee8 solid;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button.collapsed {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #022e50;
    box-shadow: none;
    background: #ffffff;
    border: 1px #dcf2ff solid;
  }

  .col-tab-responsive-mobile-wcu .accordion .accordion-item .accordion-body {
    padding: 30px 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    img {
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    h1.title-content-tabs {
    color: #022e50;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.42px;
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    h3.title-content-tabs {
    color: #022e50;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.42px;
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    p.text-content-tabs {
    color: #6e7888;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 0.42px;
    margin-bottom: 0px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .col-main-page .top-page-courier-service .col-in-area {
    padding: 0px 0px 0px 0px;
  }

  .content .thumbnail {
    height: auto;
    max-height: 460px;
    min-height: 460px;
  }

  .content .thumbnail .caption {
    padding: 30px;
    height: 225px;
    overflow: scroll;
  }

  .content .col-talk .col-btn-talk .btn-talk {
    margin: 0px;
  }

  .content .col-talk h4 {
    position: relative;
    top: 20px;
  }

  .col-main-page .top-page-courier-service.top-page-sea-freight.tpsf {
    /*height: 560px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-sea-freight.tpsf
    .page-bg-overlay {
    /*height: 560px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-air-freight {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-air-freight
    .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-sea-freight.tpsf {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-sea-freight.tpsf
    .page-bg-overlay {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-delivery-cartage {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-delivery-cartage
    .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-custom-clearance {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-custom-clearance
    .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-cold-storage-warehousing {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-cold-storage-warehousing
    .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-contract-project-logistics {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-contract-project-logistics
    .page-bg-overlay {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-company {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-company .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-office-location {
    /*height: 480px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-office-location
    .page-bg-overlay {
    /*height: 480px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-cs-air-freight {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-cs-air-freight
    .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-sea-freight {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-sea-freight
    .page-bg-overlay {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-land-truck {
    /*height: 480px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-land-truck
    .page-bg-overlay {
    /*height: 480px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-distribution-hub {
    /*height: 480px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-distribution-hub
    .page-bg-overlay {
    /*height: 480px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-ieps {
    /*height: 480px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-ieps .page-bg-overlay {
    /*height: 480px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.page-contact {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.page-contact .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .top-page-courier-service.page-contact.top-page-office-location {
    /*height: 450px;*/
    height: 100vh;
  }

  .top-page-courier-service.page-contact.top-page-office-location
    .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .content .col-talk .col-btn-talk {
    margin-top: 0px;
  }

  .content .col-talk h4 {
    position: relative;
    top: 10px;
  }

  .content .col-tabs-why-choose-us {
    display: none;
  }

  .col-tab-responsive-mobile-wcu {
    margin-top: 15px;
    display: block;
  }

  .col-tab-responsive-mobile-wcu .accordion .accordion-item {
    margin-top: 15px;
    border: 0px #dcf2ff solid;
    border-radius: 10px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    box-shadow: none;
    background: #02bee8;
    border: 1px #02bee8 solid;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button.collapsed {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #022e50;
    box-shadow: none;
    background: #ffffff;
    border: 1px #dcf2ff solid;
  }

  .col-tab-responsive-mobile-wcu .accordion .accordion-item .accordion-body {
    padding: 30px 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    img {
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    h1.title-content-tabs {
    color: #022e50;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.42px;
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    h3.title-content-tabs {
    color: #022e50;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.42px;
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    p.text-content-tabs {
    color: #6e7888;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 0.42px;
    margin-bottom: 0px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .col-main-page .top-page-courier-service .col-in-area {
    padding: 0px 0px 0px 0px;
  }

  .content .thumbnail {
    height: auto;
    max-height: 460px;
    min-height: 460px;
  }

  .content .thumbnail .caption {
    padding: 30px;
    height: 225px;
    overflow: scroll;
  }

  .content .col-talk .col-btn-talk .btn-talk {
    margin: 0px;
  }

  .content .col-talk h4 {
    position: relative;
    top: 20px;
  }

  .col-main-page .top-page-courier-service.top-page-sea-freight.tpsf {
    /*height: 560px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-sea-freight.tpsf
    .page-bg-overlay {
    /*height: 560px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-air-freight {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-air-freight
    .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-sea-freight.tpsf {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-sea-freight.tpsf
    .page-bg-overlay {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-delivery-cartage {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-delivery-cartage
    .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-custom-clearance {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-custom-clearance
    .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-cold-storage-warehousing {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-cold-storage-warehousing
    .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-contract-project-logistics {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-contract-project-logistics
    .page-bg-overlay {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-company {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-company .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-office-location {
    /*height: 480px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-office-location
    .page-bg-overlay {
    /*height: 480px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-cs-air-freight {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-cs-air-freight
    .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-sea-freight {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-sea-freight
    .page-bg-overlay {
    /*height: 510px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-land-truck {
    /*height: 480px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-land-truck
    .page-bg-overlay {
    /*height: 480px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-distribution-hub {
    /*height: 480px;*/
    height: 100vh;
  }

  .col-main-page
    .top-page-courier-service.top-page-distribution-hub
    .page-bg-overlay {
    /*height: 480px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-ieps {
    /*height: 480px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.top-page-ieps .page-bg-overlay {
    /*height: 480px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.page-contact {
    /*height: 450px;*/
    height: 100vh;
  }

  .col-main-page .top-page-courier-service.page-contact .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .top-page-courier-service.page-contact.top-page-office-location {
    /*height: 450px;*/
    height: 100vh;
  }

  .top-page-courier-service.page-contact.top-page-office-location
    .page-bg-overlay {
    /*height: 450px;*/
    height: 100vh;
  }

  .content .col-talk .col-btn-talk {
    margin-top: 0px;
  }

  .content .col-talk h4 {
    position: relative;
    top: 10px;
  }

  .content .col-tabs-why-choose-us {
    display: none;
  }

  .col-tab-responsive-mobile-wcu {
    margin-top: 15px;
    display: block;
  }

  .col-tab-responsive-mobile-wcu .accordion .accordion-item {
    margin-top: 15px;
    border: 0px #dcf2ff solid;
    border-radius: 10px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    box-shadow: none;
    background: #02bee8;
    border: 1px #02bee8 solid;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button.collapsed {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #022e50;
    box-shadow: none;
    background: #ffffff;
    border: 1px #dcf2ff solid;
  }

  .col-tab-responsive-mobile-wcu .accordion .accordion-item .accordion-body {
    padding: 30px 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    img {
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    h1.title-content-tabs {
    color: #022e50;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.42px;
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    h3.title-content-tabs {
    color: #022e50;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.42px;
    margin-bottom: 15px;
  }

  .col-tab-responsive-mobile-wcu
    .accordion
    .accordion-item
    .accordion-body
    p.text-content-tabs {
    color: #6e7888;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 0.42px;
    margin-bottom: 0px;
  }
}

@media (min-width: 1200px) and (max-width: 1279px) {
  .content .thumbnail {
    height: auto;
    max-height: 460px;
    min-height: 460px;
  }

  .content .thumbnail .caption {
    padding: 30px;
    height: 225px;
    overflow: scroll;
  }

  .content .col-talk h4 {
    position: relative;
    top: 10px;
  }

  .content .col-talk .col-btn-talk {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 60px) and (max-width: 300px) {
  .jvectormap-container {
    margin-top: 15px;
    width: 230px;
    margin-left: 5%;
    height: 240px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 360px) {
  .jvectormap-container {
    margin-top: 5px;
    width: 270px;
    margin-left: 12%;
    height: 240px;
  }
}
@media only screen and (min-width: 361px) and (max-width: 374px) {
  .jvectormap-container {
    margin-top: 5px;
    width: 310px;
    margin-left: 4%;
    height: 240px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 394px) {
  .jvectormap-container {
    margin-top: 5px;
    width: 310px;
    margin-left: 6%;
    height: 240px;
  }
}
@media only screen and (min-width: 395px) and (max-width: 401px) {
  .jvectormap-container {
    margin-top: 10px;
    width: 325px;
    margin-left: 7%;
    height: 240px;
  }
}
@media only screen and (min-width: 402px) and (max-width: 460px) {
  .jvectormap-container {
    margin-top: 15px;
    width: 330px;
    margin-left: 7%;
    height: 280px;
  }
}
@media only screen and (min-width: 461px) and (max-width: 485px) {
  .jvectormap-container {
    margin-top: 0px;
    width: 360px;
    margin-left: 13%;
    height: 280px;
  }
}
@media only screen and (min-width: 486px) and (max-width: 520px) {
  .jvectormap-container {
    margin-top: 10%;
    width: 390px;
    margin-left: 10%;
    height: 280px;
  }
}
@media only screen and (min-width: 521px) and (max-width: 539px) {
  .jvectormap-container {
    margin-top: 10%;
    width: 400px;
    margin-left: 12%;
    height: 280px;
  }
}

@media only screen and (min-width: 540px) and (max-width: 600px) {
  .jvectormap-container {
    margin-top: 10%;
    width: 415px;
    margin-left: 12%;
    height: 280px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 767px) {
  .jvectormap-container {
    margin-top: 11%;
    width: 410px;
    margin-left: 13%;
    height: 280px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .jvectormap-container {
    margin-top: 3%;
    width: 560px;
    margin-left: 13%;
    height: 280px;
  }
}
@media only screen and (min-width: 801px) and (max-width: 980px) {
  .jvectormap-container {
    margin-top: 3%;
    width: 570px;
    margin-left: 11%;
    height: 280px;
  }
}
@media only screen and (min-width: 981px) and (max-width: 991px) {
  .jvectormap-container {
    margin-top: 3%;
    width: 570px;
    margin-left: 11%;
    height: 380px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 999px) {
  .jvectormap-container {
    margin-top: 2%;
    width: 740px;
    margin-left: 13%;
    height: 380px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1009px) {
  .jvectormap-container {
    margin-top: 2%;
    width: 740px;
    margin-left: 13%;
    height: 380px;
  }
}
@media only screen and (min-width: 1010px) and (max-width: 1024px) {
  .jvectormap-container {
    margin-top: 2%;
    width: 740px;
    margin-left: 13%;
    height: 380px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .jvectormap-container {
    margin-top: 2%;
    width: 740px;
    margin-left: 17%;
    height: 380px;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1300px) {
  .jvectormap-container {
    margin-top: 20px;
    width: 880px;
    margin-left: 11%;
    height: 380px;
  }
}
@media only screen and (min-width: 1301px) and (max-width: 1400px) {
  .jvectormap-container {
    margin-top: 20px;
    width: 960px;
    margin-left: 7%;
    height: 380px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1440px) {
  .jvectormap-container {
    margin-top: 20px;
    width: 1300px;
    margin-left: 0%;
    height: 380px;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1600px) {
  .jvectormap-container {
    margin-top: 20px;
    width: 1350px;
    /* margin-left: -10%; */
    height: 380px;
  }
}
@media only screen and (min-width: 1601px) and (max-width: 1920px) {
  .jvectormap-container {
    margin-top: 30px;
    width: 1400px;
    margin-left: 0%;
    height: 380px;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 1921px) and (max-width: 2560px) {
  .jvectormap-container {
    margin-top: 30px;
    width: 1500px;
    margin-left: 0%;
    height: 380px;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 2561px) {
  .jvectormap-container {
    margin-top: 30px;
    width: 1500px;
    margin-left: 0%;
    height: 380px;
    margin-bottom: 10px;
  }
}

.jvectormap-tip {
  position: fixed;
  display: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #292929;
  color: white;
  font-family: sans-serif, Verdana;
  font-size: smaller;
  padding: 3px;
  pointer-events: none;
  z-index: 999;
}

.codrops-top-for-page-detail.nav-blogs-detail {
  background-color: rgb(2, 46, 80);
}

.content img.card-img-top.image-blogs {
  height: 100%;
  min-height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.top-consultation {
  margin-top: 3px !important;
}

.col-our-office-location .card {
  margin-bottom: 45px !important;
}

.col-our-office-location .card .card-img-top.image-blogs {
  margin-bottom: 15px;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.col-our-office-location .card .card-body.card-body-blogs h6.card-subtitle {
  font-size: 12px;
  color: #999 !important;
}

.col-our-office-location .card .card-body.card-body-blogs h5.card-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #022e50;
}

.col-our-office-location .card .card-body.card-body-blogs p.card-text {
  color: #6e7888;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0.42px;
  margin-bottom: 15px;
}

nav ul.pagination li.page-item a.page-link {
  font-size: 14px;
  color: #6e7888;
}

nav ul.pagination li.page-item a.page-link:hover,
nav ul.pagination li.page-item a.page-link:focus,
nav ul.pagination li.page-item a.page-link:active {
  font-size: 14px;
  background-color: rgb(2, 46, 80);
  border: 1px rgb(2, 46, 80) solid;
  color: #fff;
}

nav ul.pagination li.page-item.active .page-link {
  font-size: 14px;
  background-color: rgb(2, 46, 80);
  border: 1px rgb(2, 46, 80) solid;
}

/* 404 PAGE */
.page-404 {
  text-align: center;
  height: 100vh;
}

.page-404 .content {
  margin-bottom: 50px;
}

.page-404 .heading {
  text-align: center;
  font-size: 9em;
  line-height: 1.3em;
  margin: 2rem 0 0.5rem 0;
  padding: 0;
  text-shadow: 0 0 1rem #fefefe;
}

.page-404 .subheading {
  text-align: center;
  max-width: 480px;
  font-size: 1.5em;
  line-height: 1.15em;
  padding: 0 1rem;
  margin: 0 auto;
}

.page-404 .button-404 {
  width: 150px;
  background: #102d4e;
  box-shadow: none;
  border-radius: 0.2rem;
  /* display: flex; */
  align-items: center;
  padding: 0.3rem 0.5rem;
  border: 1px solid #022e50;
  color: #fff;
}

.content .link-thumbnail .thumbnail img.card-international-delivery {
  height: 100%;
  min-height: 220px;
  max-height: 220px;
}

.col-main-page
  .top-page-courier-service
  .col-in-area
  .col-btn-talk
  .btn-talk.btn-cta-detail {
  display: block;
  position: relative;
}

.col-btn-talk.col-btn-talk-blog {
  margin-top: 30px !important;
  margin-bottom: 30px;
  position: relative !important;
}

.col-btn-talk.col-btn-talk-blog .btn-cta-detail {
  display: block !important;
  position: relative !important;
  background: rgba(2, 46, 80) !important;
  background-color: rgba(2, 46, 80) !important;
  border: 2px solid #ffffff !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
  padding: 5px 15px !important;
  height: 50px !important;
  margin: 0px 5px 0px 0px !important;
  width: 100% !important;
  max-width: 200px !important;
  transition: 0.5s ease !important;
}

.col-btn-talk.col-btn-talk-blog .btn-cta-detail img {
  width: 100% !important;
  max-width: 22px !important;
  margin-right: 5px !important;
}

.col-btn-talk.col-btn-talk-blog .btn-cta-detail span {
  color: #ffffff !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  letter-spacing: 0.42px !important;
  font-family: 'IBM Plex Sans', sans-serif !important;
  transition: transform 0.5s ease !important;
  position: relative !important;
  top: 2px !important;
}

.card.col-contents-box-blogs {
  border-radius: 12px;
}

.content.contents-journey {
  padding-top: 0px !important;
}

.content.contents-journey .col-our-office-location h3 img {
  margin-top: 15px;
}

.col-main-page.col-main-page-detail.pages-ol {
  margin-bottom: 0px !important;
}

.page-ol-col-contents {
  margin-top: 75px;
}
