html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-weight: 500;
  font-size: 14px;
  color: #3e4756;
  overflow-x: hidden;
  font-family: "IBM Plex Sans", sans-serif;
}

.ie7 body {
  overflow: hidden;
}

a {
  color: #02bee8;
  text-decoration: none;
}

.clr {
  clear: both;
}

/*=====***** BACKGROUND BANNER *****=====*/

.stage {
  position: fixed;
  width: 100%;
  height: 100%;
  content: "";
  background-image: linear-gradient(
    180deg,
    rgba(2, 46, 80, 0.62) 0,
    rgba(2, 46, 80, 0.22) 25%,
    rgba(2, 46, 80, 0.22) 45%,
    rgba(0, 0, 0, 0.82)
  );
}

.stage a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
}

.stage a::after {
  content: "";
  background-image: linear-gradient(
    180deg,
    rgba(2, 46, 80, 0.62) 0,
    rgba(2, 46, 80, 0.22) 25%,
    rgba(2, 46, 80, 0.22) 45%,
    rgba(0, 0, 0, 0.82)
  );
}

.stage a img {
  padding: 0px;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: none;
  position: absolute;
  z-index: 0;
  object-fit: cover;
}

.stage a:nth-of-type(1) {
  animation-name: fader;
  -webkit-animation-delay: 6s;
  -moz-animation-delay: 6s;
  -o-animation-delay: 6s;
  -ms-animation-delay: 6s;
  animation-delay: 6s;
  animation-duration: 1s;
  z-index: 5;
}

.stage a:nth-of-type(2) {
  z-index: 3;
  -webkit-animation-delay: 12s;
  -moz-animation-delay: 12s;
  -o-animation-delay: 12s;
  -ms-animation-delay: 12s;
  animation-delay: 12s;
  animation-duration: 1s;
}

.stage a:nth-of-type(n + 3) {
  display: none;
}

@keyframes fader {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.stage a .col-text {
  position: relative;
  text-align: center;
  padding: 0px 150px 45px 150px;
  width: 1000vh;
  height: 100vh;
  vertical-align: middle;
  display: table-cell;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.2;
  text-shadow: 2px 2px 8px rgba(2, 46, 80, 0.42);
  color: #fff;
  animation: titleAnimation 36s linear infinite 0s;
}

#stage .col-text h1 {
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.32;
  text-shadow: 2px 2px 8px rgba(2, 46, 80, 0.42);
  color: #fff;
}

#stage .col-text p {
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.32;
  text-shadow: 2px 2px 8px rgba(2, 46, 80, 0.42);
  color: #fff;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.stage .bg-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 9;
  list-style: none;
  padding-left: 0;
  content: "";
  background-image: linear-gradient(
    180deg,
    rgba(2, 46, 80, 0.62) 0,
    rgba(2, 46, 80, 0.22) 25%,
    rgba(2, 46, 80, 0.22) 45%,
    rgba(0, 0, 0, 0.82)
  );
}

/*=====***** CODROPS *****=====*/

.codrops-top {
  width: 100%;
  background: transparent;
  opacity: 1;
  text-transform: uppercase;
  z-index: 9999;
  position: absolute;
  height: 90px;
  padding: 1.5rem 3rem;
}

.codrops-top span {
  padding: 0px;
  display: block;
  float: left;
  transition: transform 0.5s ease;
}

.codrops-top a {
  padding: 0;
  display: block;
  float: left;
  transition: transform 0.5s ease;
}

.codrops-top span:hover {
  transition: transform 0.5s ease;
}

.codrops-top a:hover {
  transition: transform 0.5s ease;
}

.codrops-top span img {
  width: 100%;
  max-width: 125px;
  position: relative;
  top: -10px;
  margin-right: 0px;
  transition: transform 0.5s ease;
}

.codrops-top a img {
  width: 100%;
  max-width: 125px;
  position: relative;
  top: -10px;
  margin-right: 0px;
  transition: transform 0.5s ease;
}

.codrops-top span.right {
  float: right;
  display: inline-flex;
  padding: 10px 0px;
  margin: 0px;
}

.codrops-top span.right.display-mobile {
  display: none;
}

.codrops-top span.right span {
  float: none;
  display: inline;
  transition: transform 0.5s ease;
}

.codrops-top span.right span.link-free-consultation {
  color: #ffffff;
  font-weight: 700;
  margin-left: 25px;
  letter-spacing: 0.42px;
  transition: transform 0.5s ease;
}

/*.codrops-top span.right form {
    position: relative;
    top: -9px;
}

.codrops-top span.right form .input-group .form-control {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    background-color: transparent;
    height: 40px;
    letter-spacing: .42px;
    text-transform: none;
    border: 1px #ffffff solid;
    border-right: 0px;
}

.codrops-top span.right form .input-group .form-control:focus {
    box-shadow: none;
    transition: transform .5s ease;
}

.codrops-top span.right form .input-group .form-control::placeholder {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    opacity: 0.72;
}

.codrops-top span.right form .input-group .input-group-text {
    background: transparent;
    border: 1px solid #fff;
    border-left: 0px;
}*/

.codrops-top span.right ul.top-nav-menu {
  margin-left: 25px;
  padding-left: 0px;
  transition: 0.5s ease;
}

.codrops-top span.right ul.top-nav-menu li a span img {
  max-width: 30px !important;
  margin-right: 5px !important;
  top: 0px !important;
  transition: 0.5s ease !important;
}

.codrops-top span.right ul.top-nav-menu li a span.text-language {
  position: relative;
  top: 1px;
}

.codrops-top span.right ul.top-nav-menu li {
  padding: 0px;
  position: relative;
  top: -4px;
  margin-right: 0px;
  transition: 0.5s ease;
}

.codrops-top span.right ul.top-nav-menu li ul.sub-nav-content {
  top: 30px;
  min-width: 60px;
  transition: 0.5s ease;
  padding: 5px 20px 5px 20px;
  border-radius: 10px;
}

.codrops-top span.right ul.top-nav-menu li ul.sub-nav-content li {
  padding: 0px 0px 0px 0px;
  transition: 0.5s ease;
  top: 0px;
  border-radius: 10px;
}

.codrops-top span.right span.language {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  margin-left: 25px;
  letter-spacing: 0.42px;
  transition: transform 0.5s ease;
}

.codrops-top span.right span.language img {
  width: 100%;
  max-width: 30px;
  padding: 6px 0px;
  margin-right: 5px;
  transition: transform 0.5s ease;
}

.codrops-top span.right span.language span.text-language {
  position: relative;
  top: -9px;
  transition: transform 0.5s ease;
}

.codrops-top span.right span.home {
  margin-left: 25px;
  transition: transform 0.5s ease;
}

.codrops-top span.right span.home span img {
  width: 100% !important;
  max-width: 28px !important;
  padding: 5px 0px !important;
  transition: transform 0.5s ease;
}

.codrops-top ul.top-nav-menu {
  display: inline-flex;
  margin: 0px;
  list-style: none;
  transition: 0.5s ease;
}

.codrops-top ul.top-nav-menu li {
  margin-right: 15px;
  padding: 10px 0px;
  transition: 0.5s ease;
}

.codrops-top ul.top-nav-menu li span {
  color: #ffffff !important;
  font-weight: 700;
  letter-spacing: 0.42px;
  text-transform: uppercase;
  font-family: "IBM Plex Sans", sans-serif;
  transition: 0.5s ease;
}

.codrops-top ul.top-nav-menu li a {
  color: #ffffff !important;
  font-weight: 700;
  letter-spacing: 0.42px;
  text-transform: uppercase;
  font-family: "IBM Plex Sans", sans-serif;
  transition: 0.5s ease;
}

.codrops-top ul.top-nav-menu li span:hover,
.codrops-top ul.top-nav-menu li span:focus,
.codrops-top ul.top-nav-menu li span:active {
  columns: #ffffff !important;
}

.codrops-top ul.top-nav-menu li a:hover,
.codrops-top ul.top-nav-menu li a:focus,
.codrops-top ul.top-nav-menu li a:active {
  columns: #ffffff !important;
}

.codrops-top ul.top-nav-menu li span.link-sub-nav {
  pointer-events: none;
}

.codrops-top ul.top-nav-menu li ul.sub-nav-content {
  display: none;
  position: absolute;
  background: rgba(2, 46, 80, 0.52);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(2, 46, 80, 0.25));
  border-radius: 10px;
  padding: 0px !important;
  margin: 0px !important;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 65px;
  list-style: none;
  animation: fade-in-move-down 0.1s;
}

.codrops-top ul.top-nav-menu li ul.sub-nav-content li {
  padding: 0px 0px;
  margin: 0px;
  position: relative;
  border-radius: 10px;
  width: 100%;
  display: flex;
}

@keyframes fade-in-move-down {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.codrops-top ul.top-nav-menu li ul.sub-nav-content li span {
  display: block;
  position: relative;
  border-radius: 10px;
  width: 100%;
  padding: 10px 25px;
  color: #ffffff;
  font-weight: 700;
  letter-spacing: 0.42px;
  text-transform: uppercase;
  font-family: "IBM Plex Sans", sans-serif;
  transition: 0.5s ease;
  opacity: 0.72;
}

.codrops-top ul.top-nav-menu li ul.sub-nav-content li a {
  display: block;
  position: relative;
  border-radius: 10px;
  width: 100%;
  padding: 10px 25px;
  color: #ffffff;
  font-weight: 700;
  letter-spacing: 0.42px;
  text-transform: uppercase;
  font-family: "IBM Plex Sans", sans-serif;
  transition: 0.5s ease;
  opacity: 0.72;
}

.codrops-top ul.top-nav-menu li ul.sub-nav-content li span:hover,
.codrops-top ul.top-nav-menu li ul.sub-nav-content li span:focus,
.codrops-top ul.top-nav-menu li ul.sub-nav-content li span:active {
  /*background: #02bee8;*/
  border-radius: 10px;
  transition: 0.5s ease;
  opacity: 1;
}

.codrops-top ul.top-nav-menu li ul.sub-nav-content li a:hover,
.codrops-top ul.top-nav-menu li ul.sub-nav-content li a:focus,
.codrops-top ul.top-nav-menu li ul.sub-nav-content li a:active {
  /*background: #02bee8;*/
  border-radius: 10px;
  transition: 0.5s ease;
  opacity: 1;
}

.codrops-top ul.top-nav-menu li:hover ul.sub-nav-content {
  display: block;
  transition: 0.5s ease;
}

.codrops-top ul.top-nav-menu li ul.sub-nav-content li .subs-nav {
  left: 338px;
  display: none;
  position: absolute;
  background: rgba(2, 46, 80, 0.52);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(2, 46, 80, 0.25));
  border-radius: 10px;
  padding: 0px !important;
  margin: 0px !important;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 0;
  list-style: none;
  min-width: 310px;
  animation: fade-in-move-down 0.1s;
}

.codrops-top ul.top-nav-menu li ul.sub-nav-content li .subs-nav li {
  padding: 0px 0px;
  border-radius: 10px;
  margin: 0px;
  width: 100%;
}

@keyframes fade-in-move-downs {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.codrops-top ul.top-nav-menu li ul.sub-nav-content li .subs-nav li a {
  width: 100%;
  padding: 10px 25px;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 700;
  letter-spacing: 0.42px;
  text-transform: uppercase;
  font-family: "IBM Plex Sans", sans-serif;
  transition: 0.5s ease;
  opacity: 0.72;
}

.codrops-top ul.top-nav-menu li ul.sub-nav-content li .subs-nav li a:hover,
.codrops-top ul.top-nav-menu li ul.sub-nav-content li .subs-nav li a:focus,
.codrops-top ul.top-nav-menu li ul.sub-nav-content li .subs-nav li a:active {
  opacity: 1;
}

.codrops-top ul.top-nav-menu li ul.sub-nav-content li:hover a.link-sub-nav,
.codrops-top ul.top-nav-menu li ul.sub-nav-content li:focus a.link-sub-nav,
.codrops-top ul.top-nav-menu li ul.sub-nav-content li:active a.link-sub-nav {
  opacity: 1 !important;
}

.codrops-top ul.top-nav-menu li ul.sub-nav-content li:hover .subs-nav {
  display: block;
  transition: 0.5s ease;
  margin-left: 10px;
}

.codrops-top .sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 0px;
  padding-bottom: 15px;
}

.codrops-top .sidenav .col-top-nav-push {
  padding: 5px 15px;
  border-bottom: 1px #dcf2ff solid;
  display: flex;
  margin-bottom: 15px;
}

.codrops-top .sidenav .col-top-nav-push span img {
  top: 0px;
  width: 100% !important;
  max-width: 100px !important;
  min-width: 100px !important;
}

.codrops-top .sidenav .col-top-nav-push .closebtn {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 30px;
  margin-left: 50px;
  color: #a2acbd;
  font-weight: 500;
  transition: 0.5s ease;
}

.codrops-top .sidenav .col-top-nav-push .closebtn:hover,
.codrops-top .sidenav .col-top-nav-push .closebtn:focus,
.codrops-top .sidenav .col-top-nav-push .closebtn:active {
  color: #02bee8;
  transition: 0.5s ease;
  cursor: pointer;
}

.codrops-top span.right ul.top-nav-menu li span img {
  max-width: 30px;
  margin-right: 10px;
  top: 0;
  transition: 0.5s ease;
}

.codrops-top span.right span.home img {
  width: 100%;
  max-width: 28px;
  transition: transform 0.5s ease;
  top: -4px;
}

/*=====***** TAB MENU ICON CKL *****=====*/

.tab-menu-icon-ckl {
  background-color: transparent;
  text-align: center;
  width: 100%;
  bottom: 15px;
  left: 0;
  position: absolute;
  z-index: 10;
  animation: 3s anim-lineUp-navBottom ease-out;
}

@keyframes anim-lineUp-navBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  20% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.2;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.tab-menu-icon-ckl button {
  padding: 0px;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  width: 100%;
  max-width: 120px;
  margin: 0px 15px;
  transition: 0.5s ease;
  opacity: 0.62;
  z-index: 10;
  height: 100%;
  max-height: 110px;
  min-height: 110px;
  display: inline-grid;
}

.tab-menu-icon-ckl button.tablinks.tablinks-international {
  max-width: 160px;
}

.tab-menu-icon-ckl button.tablinks.active {
  opacity: 1;
}

.tab-menu-icon-ckl button:hover {
  transition: 0.5s ease;
  opacity: 1;
}

.tab-menu-icon-ckl button:hover img {
  background-color: transparent;
  /*transform: translateY(-1.2em);*/
  transition: 0.5s ease;
  transform: scale(1.14);
}

.tab-menu-icon-ckl button img {
  /* width: 100%; */
  /* max-width: 70px; */
  /* min-width: 70px; */
  /* min-width: 0px !important; */
  /* max-width: 0px !important; */
  /* min-height: 0px !important; */
  /* max-height: 0px !important; */
  height: 45px !important;
  width: 45px !important;
  margin: 0px auto !important;
  transition: 0.5s ease !important;
}

.tab-menu-icon-ckl button p {
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.42px;
  text-transform: uppercase;
  font-family: "IBM Plex Sans", sans-serif;
  transition: 0.5s ease;
  position: absolute;
  top: 50px;
  width: 120px;
  margin-top: 5px !important;
  margin-bottom: 0px;
}

.tab-menu-icon-ckl button.tablinks.tablinks-international p {
  width: 160px;
}

.tabcontent_menu_ckl {
  padding: 0px;
  display: none;
  z-index: 1000;
  position: relative;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

/*=====***** NAV TABS WHY CHOOSE US *****=====*/

.page-nav-tabs-why-choose-us {
  background: #022e50;
  /* background-image: url(../assets/images/bg-why-choose-us.jpg); */
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  z-index: 9;
  animation: fadein 1s ease-in;
  -moz-animation: fadein 1s ease-in;
  -webkit-animation: fadein 1s ease-in;
  -o-animation: fadein 1s ease-in;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.page-nav-tabs-why-choose-us .bg-overlay::after {
  content: "";
  /* background: transparent url(../assets/images/pattern.png) repeat top left; */
  opacity: 0.72;
  background-image: linear-gradient(
    180deg,
    rgba(2, 46, 80, 0.62) 0,
    rgba(2, 46, 80, 0.42) 25%,
    rgba(2, 46, 80, 0.22) 45%,
    rgba(0, 0, 0, 0.82)
  );
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
  list-style: none;
  padding-left: 0;
}

.page-nav-tabs-why-choose-us .col-content-why-choose-us {
  position: relative;
  text-align: center;
  padding: 0 60px 0 60px;
  width: 1000vh;
  height: 100vh;
  padding-bottom: 45px;
  vertical-align: middle;
  display: table-cell;
}

.page-nav-tabs-why-choose-us .col-content-why-choose-us .col-box-why-choose-us {
  background: rgba(2, 46, 80, 0.52);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(2, 46, 80, 0.25));
  padding: 15px 0px;
}

.page-nav-tabs-why-choose-us
  .col-content-why-choose-us
  .btn-content-why-choose-us {
  background: transparent;
  border: none;
  width: 150px;
  margin: 30px 10px;
  vertical-align: top;
  /* margin-bottom: 30px; */
  transition: transform 0.5s ease;
  cursor: default;
}

.page-nav-tabs-why-choose-us
  .col-content-why-choose-us
  .btn-content-why-choose-us
  img {
  width: 90px;
  /*background: rgba(2, 46, 80, 0.52);*/
  /*border: 2px #fff solid;*/
  /*border-radius: 10px;*/
  padding: 25px 15px 0px 15px;
  margin-bottom: 15px;
  transition: transform 0.5s ease;
}

.page-nav-tabs-why-choose-us
  .col-content-why-choose-us
  .col-box-why-choose-us
  .btn-content-why-choose-us
  img {
  padding-top: 0px;
}

.page-nav-tabs-why-choose-us
  .col-content-why-choose-us
  .btn-content-why-choose-us
  p {
  color: #ffffff;
  font-weight: 700;
  letter-spacing: 0.42px;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-family: "IBM Plex Sans", sans-serif;
  transition: transform 0.5s ease;
  text-align: center;
  font-size: 13px;
}

.page-nav-tabs-why-choose-us
  .col-content-why-choose-us
  .btn-content-why-choose-us:hover,
.page-nav-tabs-why-choose-us
  .col-content-why-choose-us
  .btn-content-why-choose-us:focus,
.page-nav-tabs-why-choose-us
  .col-content-why-choose-us
  .btn-content-why-choose-us:active {
  opacity: 0.72;
  transition: 0.5s ease;
}

.page-nav-tabs-why-choose-us
  .col-content-why-choose-us
  .btn-content-why-choose-us:hover
  img {
  transition: 0.5s ease;
  transform: scale(0.92);
}

/*=====***** NAV TABS COURIER SERVICES *****=====*/

.page-nav-tabs-courier-services {
  background-color: #022e50;
  /* background-image: url(../assets/images/courier-services.jpg); */
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  z-index: 9;
  animation: fadein 1s;
  -moz-animation: fadein 1s;
  -webkit-animation: fadein 1s;
  -o-animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.page-nav-tabs-courier-services .bg-overlay::after {
  content: "";
  /* background: transparent url(../assets/images/pattern.png) repeat top left; */
  opacity: 0.72;
  background-image: linear-gradient(
    180deg,
    rgba(2, 46, 80, 0.42) 0,
    rgba(2, 46, 80, 0.22) 25%,
    rgba(2, 46, 80, 0.22) 45%,
    rgba(0, 0, 0, 0.62)
  );
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
  list-style: none;
  padding-left: 0;
}

.page-nav-tabs-courier-services .col-content-courier-services {
  position: relative;
  text-align: center;
  padding: 0 60px 0 60px;
  width: 1000vh;
  height: 100vh;
  padding-bottom: 45px;
  vertical-align: middle;
  display: table-cell;
}

.page-nav-tabs-courier-services .col-content-courier-services h3 {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.42px;
  text-align: center;
  font-family: "IBM Plex Sans", sans-serif;
  transition: transform 0.5s ease;
  margin-bottom: 30px;
}

.page-nav-tabs-courier-services
  .col-content-courier-services
  .btn-content-courier-services {
  background: transparent;
  border: none;
  width: 150px;
  margin: 0px 10px;
  vertical-align: top;
  margin-bottom: 15px;
  transition: transform 0.5s ease;
}

.page-nav-tabs-courier-services
  .col-content-courier-services
  .btn-content-courier-services
  img {
  width: 90px;
  background: rgba(2, 46, 80, 0.52);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  filter: drop-shadow(0px 4px 4px rgba(2, 46, 80, 0.25));
  border: 2px #fff solid;
  border-radius: 10px;
  padding: 25px 15px 25px 15px;
  margin-bottom: 10px;
  transition: transform 0.5s ease;
}

.page-nav-tabs-courier-services
  .col-content-courier-services
  .btn-content-courier-services
  p {
  color: #ffffff;
  font-weight: 700;
  letter-spacing: 0.42px;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-family: "IBM Plex Sans", sans-serif;
  transition: transform 0.5s ease;
  text-align: center;
  font-size: 13px;
}

.page-nav-tabs-courier-services
  .col-content-courier-services
  .btn-content-courier-services:hover,
.page-nav-tabs-courier-services
  .col-content-courier-services
  .btn-content-courier-services:focus,
.page-nav-tabs-courier-services
  .col-content-courier-services
  .btn-content-courier-services:active {
  opacity: 0.72;
  transition: 0.5s ease;
}

.page-nav-tabs-courier-services
  .col-content-courier-services
  .btn-content-courier-services:hover
  img {
  transition: 0.5s ease;
  transform: scale(0.92);
}

/*=====***** NAV TABS INTERNATIONAL *****=====*/

.page-nav-tabs-international {
  background: #022e50;
  /* background-image: url(../assets/images/9.jpg); */
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  z-index: 9;
  animation: fadein 1s ease-in;
  -moz-animation: fadein 1s ease-in;
  -webkit-animation: fadein 1s ease-in;
  -o-animation: fadein 1s ease-in;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.page-nav-tabs-international .bg-overlay::after {
  content: "";
  /* background: transparent url(../assets/images/pattern.png) repeat top left; */
  opacity: 0.72;
  background-image: linear-gradient(
    180deg,
    rgba(2, 46, 80, 0.82) 0,
    rgba(2, 46, 80, 0.42) 25%,
    rgba(2, 46, 80, 0.42) 45%,
    rgba(0, 0, 0, 0.82)
  );
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
  list-style: none;
  padding-left: 0;
}

.page-nav-tabs-international .col-content-international {
  position: relative;
  text-align: center;
  padding: 0 150px 0 150px;
  width: 1000vh;
  height: 100vh;
  padding-bottom: 45px;
  vertical-align: middle;
  display: table-cell;
}

.page-nav-tabs-international
  .col-content-international
  .btn-content-international {
  background: transparent;
  border: none;
  width: 150px;
  margin: 0px 10px;
  margin-bottom: 0px;
  vertical-align: top;
  margin-bottom: 15px;
  transition: transform 0.5s ease;
}

.page-nav-tabs-international
  .col-content-international
  .btn-content-international
  img {
  width: 90px;
  background: rgba(2, 46, 80, 0.52);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  filter: drop-shadow(0px 4px 4px rgba(2, 46, 80, 0.25));
  border: 2px #fff solid;
  border-radius: 10px;
  padding: 25px 15px 25px 15px;
  margin-bottom: 10px;
  transition: transform 0.5s ease;
}

.page-nav-tabs-international
  .col-content-international
  .btn-content-international
  p {
  color: #ffffff;
  font-weight: 700;
  letter-spacing: 0.42px;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-family: "IBM Plex Sans", sans-serif;
  transition: transform 0.5s ease;
  text-align: center;
  font-size: 13px;
}

.page-nav-tabs-international
  .col-content-international
  .btn-content-international:hover,
.page-nav-tabs-international
  .col-content-international
  .btn-content-international:focus,
.page-nav-tabs-international
  .col-content-international
  .btn-content-international:active {
  opacity: 0.72;
  transition: 0.5s ease;
}

.page-nav-tabs-international
  .col-content-international
  .btn-content-international:hover
  img {
  transition: 0.5s ease;
  transform: scale(0.92);
}

.page-nav-tabs-international .col-content-international .col-btn-talk {
  margin-top: 15px;
}

.page-nav-tabs-international
  .col-content-international
  .col-btn-talk
  .btn-talk {
  background: rgba(2, 46, 80, 0.52);
  border: 2px solid #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  filter: drop-shadow(0px 4px 4px rgba(2, 46, 80, 0.25));
  border-radius: 10px;
  padding: 5px 15px;
  height: 50px;
  margin: 0px 5px;
  width: 100%;
  max-width: 200px;
  transition: 0.5s ease;
}

.page-nav-tabs-international
  .col-content-international
  .col-btn-talk
  .btn-talk.bt-learn-more {
  background: rgba(2, 46, 80, 0.52);
  border: 2px solid #ffffff;
  transition: 0.5s ease;
}

.page-nav-tabs-international
  .col-content-international
  .col-btn-talk
  .btn-talk:hover,
.page-nav-tabs-international
  .col-content-international
  .col-btn-talk
  .btn-talk:focus,
.page-nav-tabs-international
  .col-content-international
  .col-btn-talk
  .btn-talk:active {
  transition: 0.5s ease;
  opacity: 0.72;
}

.page-nav-tabs-international
  .col-content-international
  .col-btn-talk
  .btn-talk
  img {
  width: 100%;
  max-width: 26px;
  margin-right: 5px;
}

.page-nav-tabs-international
  .col-content-international
  .col-btn-talk
  .btn-talk
  span {
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.42px;
  font-family: "IBM Plex Sans", sans-serif;
  transition: transform 0.5s ease;
  position: relative;
  top: 2px;
}

/*=====***** NAV TABS SUPPLY *****=====*/

.page-nav-tabs-supply {
  background: #022e50;
  /* background-image: url(../assets/images/10.jpg); */
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  z-index: 9;
  animation: fadein 1s ease-in;
  -moz-animation: fadein 1s ease-in;
  -webkit-animation: fadein 1s ease-in;
  -o-animation: fadein 1s ease-in;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.page-nav-tabs-supply .bg-overlay::after {
  content: "";
  /* background: transparent url(../assets/images/pattern.png) repeat top left; */
  opacity: 0.72;
  background-image: linear-gradient(
    180deg,
    rgba(2, 46, 80, 0.82) 0,
    rgba(2, 46, 80, 0.42) 25%,
    rgba(2, 46, 80, 0.22) 45%,
    rgba(0, 0, 0, 0.82)
  );
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
  list-style: none;
  padding-left: 0;
}

.page-nav-tabs-supply .col-content-supply {
  position: relative;
  text-align: center;
  padding: 0 150px 0 150px;
  width: 1000vh;
  height: 100vh;
  padding-bottom: 45px;
  vertical-align: middle;
  display: table-cell;
}

.page-nav-tabs-supply .col-content-supply h3 {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.42px;
  text-align: center;
  font-family: "IBM Plex Sans", sans-serif;
  transition: transform 0.5s ease;
  margin-bottom: 30px;
}

.page-nav-tabs-supply .col-content-supply p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.42px;
  margin-bottom: 30px;
  text-transform: normal;
  font-family: "IBM Plex Sans", sans-serif;
  transition: transform 0.5s ease;
  text-align: center;
}

.page-nav-tabs-supply .col-content-supply .btn-content-supply {
  background: transparent;
  border: none;
  width: 150px;
  margin: 0px 10px;
  vertical-align: top;
  margin-bottom: 15px;
  transition: transform 0.5s ease;
}

.page-nav-tabs-supply .col-content-supply .btn-content-supply img {
  width: 90px;
  background: rgba(2, 46, 80, 0.52);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  filter: drop-shadow(0px 4px 4px rgba(2, 46, 80, 0.25));
  border: 2px #fff solid;
  border-radius: 10px;
  padding: 25px 15px 25px 15px;
  margin-bottom: 10px;
  transition: transform 0.5s ease;
}

.page-nav-tabs-supply .col-content-supply .btn-content-supply p {
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.42px;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-family: "IBM Plex Sans", sans-serif;
  transition: transform 0.5s ease;
  text-align: center;
}

.page-nav-tabs-supply .col-content-supply .btn-content-supply:hover,
.page-nav-tabs-supply .col-content-supply .btn-content-supply:focus,
.page-nav-tabs-supply .col-content-supply .btn-content-supply:active {
  opacity: 0.72;
  transition: 0.5s ease;
}

.page-nav-tabs-supply .col-content-supply .btn-content-supply:hover img {
  transition: 0.5s ease;
  transform: scale(0.92);
}

/*=====***** NAV TABS OUR NETWORK *****=====*/

.page-nav-tabs-our-network {
  background: #022e50;
  /* background-image: url(../assets/images/7.jpg); */
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  z-index: 9;
  animation: fadein 1s ease-in;
  -moz-animation: fadein 1s ease-in;
  -webkit-animation: fadein 1s ease-in;
  -o-animation: fadein 1s ease-in;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.page-nav-tabs-our-network .bg-overlay::after {
  content: "";
  /* background: transparent url(../assets/images/pattern.png) repeat top left; */
  opacity: 0.72;
  background-image: linear-gradient(
    180deg,
    rgba(2, 46, 80, 0.62) 0,
    rgba(2, 46, 80, 0.22) 25%,
    rgba(2, 46, 80, 0.22) 45%,
    rgba(0, 0, 0, 0.82)
  );
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
  list-style: none;
  padding-left: 0;
}

.page-nav-tabs-our-network .col-content-our-network {
  position: relative;
  text-align: center;
  padding: 0 0px 0 0px;
  width: 1000vh;
  height: 100vh;
  padding-bottom: 45px;
  vertical-align: middle;
  display: table-cell;
}

.page-nav-tabs-our-network .col-content-our-network .box-our-network {
  background: rgba(2, 46, 80, 0.52);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(2, 46, 80, 0.25));
}

.page-nav-tabs-our-network
  .col-content-our-network
  .box-our-network
  h3.title-office {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.42px;
  text-align: left;
  padding: 30px 0px 0px 30px;
  margin-bottom: 30px;
}

.page-nav-tabs-our-network
  .col-content-our-network
  .box-our-network
  h4.title-city {
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.42px;
  font-weight: 700;
  text-align: left;
  padding: 0px 0px 0px 30px;
  margin-bottom: 15px;
}

.page-nav-tabs-our-network .col-content-our-network .box-our-network ul {
  color: #fff;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  padding-left: 45px;
}

.page-nav-tabs-our-network .col-content-our-network .box-our-network ul li {
  padding-left: 5px;
  margin-bottom: 15px;
}

.page-nav-tabs-our-network .col-content-our-network .box-our-network iframe {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 100%;
  min-height: 400px;
}

/*=====***** NAV TABS Chat *****=====*/

.page-nav-tabs-chat {
  background: #022e50;
  /* background-image: url(../assets/images/8.jpg); */
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  z-index: 9;
  animation: fadein 1s ease-in;
  -moz-animation: fadein 1s ease-in;
  -webkit-animation: fadein 1s ease-in;
  -o-animation: fadein 1s ease-in;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.page-nav-tabs-chat .bg-overlay::after {
  content: "";
  /* background: transparent url(../assets/images/pattern.png) repeat top left; */
  opacity: 0.72;
  background-image: linear-gradient(
    180deg,
    rgba(2, 46, 80, 0.82) 0,
    rgba(2, 46, 80, 0.42) 25%,
    rgba(2, 46, 80, 0.42) 45%,
    rgba(0, 0, 0, 0.82)
  );
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
  list-style: none;
  padding-left: 0;
}

.page-nav-tabs-chat .col-content-chat {
  position: relative;
  text-align: center;
  padding: 0 180px 0 180px;
  width: 1000vh;
  height: 100vh;
  padding-bottom: 45px;
  vertical-align: middle;
  display: table-cell;
}

.page-nav-tabs-chat .col-content-chat h3 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.42px;
  text-align: center;
  font-family: "IBM Plex Sans", sans-serif;
  transition: transform 0.5s ease;
  margin-bottom: 15px;
  white-space: pre-wrap;
}

.page-nav-tabs-chat .col-content-chat .col-btn-talk {
  margin-top: 30px;
}

.page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk {
  background: rgba(2, 46, 82, 0.52);
  border: 2px solid #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  filter: drop-shadow(0px 4px 4px rgba(2, 46, 80, 0.25));
  border-radius: 10px;
  padding: 5px 15px;
  height: 50px;
  margin: 0px 5px;
  width: 100%;
  max-width: 250px;
  transition: 0.5s ease;
}

.page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk:hover,
.page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk:focus,
.page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk:active {
  opacity: 0.72;
}

.page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk img {
  width: 100%;
  max-width: 30px;
  margin-right: 5px;
}

.page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk span {
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.42px;
  font-family: "IBM Plex Sans", sans-serif;
  transition: transform 0.5s ease;
  position: relative;
  top: 2px;
}

.page-nav-tabs-our-network .col-content-our-network {
  padding-bottom: 60px;
}

.page-nav-tabs-our-network .col-content-our-network .box-our-network #vmap {
  position: relative;
  top: -5px;
}

.col-our-office-location h2 {
  color: #022e50;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 0.42px;
  margin-bottom: 15px;
}

.form-contact-us .costum-input .form-control:focus {
  box-shadow: none;
  border: 1px #022e50 solid !important;
}

.form-contact-us .btn.btn-primary {
  margin-top: 30px;
  background: #022e50 !important;
  border: 1px #022e50 solid;
  font-size: 14px;
  font-weight: 600;
}

.col-content-office-location iframe {
  border-radius: 10px;
}

.col-our-office-location iframe {
  border-radius: 10px;
}

.col-our-office-location h2 {
  color: #022e50;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 0.42px;
  margin-bottom: 15px;
}

.col-our-office-location figure.image {
  margin-bottom: 30px;
}

.col-our-office-location .card {
  margin-bottom: 45px !important;
}

.col-our-office-location .card .card-img-top.image-blogs {
  height: 100%;
  min-height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-bottom: 15px;
}

.col-our-office-location .card .card-body.card-body-blogs h6.card-subtitle {
  font-size: 12px;
  color: #6e7888 !important;
}

.col-our-office-location .card .card-body.card-body-blogs h5.card-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #022e50;
}

.col-our-office-location .card .card-body.card-body-blogs p.card-text {
  color: #6e7888;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0.42px;
  margin-bottom: 15px;
}

.col-main-page.col-main-page-detail {
  margin-bottom: 0px !important;
}

.col-main-page.col-main-page-detail .col-in-area .breadcrumb {
  color: #6e7888;
}

.col-main-page.col-main-page-detail .content {
  padding-top: 75px;
}

.col-main-page.col-main-page-detail .content .col-our-office-location h1 {
  color: #022e50;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 0.42px;
  margin-bottom: 15px !important;
}

.col-main-page.col-main-page-detail .content .col-our-office-location h3 {
  color: #022e50;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.42px;
  margin-bottom: 15px !important;
}

.col-main-page.col-main-page-detail .content .col-our-office-location ul {
  padding-left: 15px;
  font-size: 14px;
  color: #6e7888;
  line-height: 28px;
}

.col-main-page.col-main-page-detail .content .col-our-office-location ul li {
  padding-left: 15px;
}

.col-main-page.col-main-page-detail .content .col-our-office-location p {
  line-height: 28px;
}

.col-main-page.col-main-page-detail
  .content
  .col-our-office-location
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button {
  font-weight: 700;
  font-size: 16px;
  box-shadow: none !important;
}

.col-main-page.col-main-page-detail
  .content
  .col-our-office-location
  .accordion
  .accordion-item
  .accordion-collpase
  .accordion-body {
  padding-left: 45px;
  font-size: 14px;
  color: #6e7888;
}

.col-main-page.col-main-page-detail .content .col-talk h2 {
  font-weight: 700 !important;
  font-size: 20px !important;
  letter-spacing: 0.42px !important;
  margin-bottom: 15px !important;
}

.col-main-page.col-main-page-detail
  .content
  .col-talk
  form
  .form-control:focus {
  box-shadow: none;
  border: 1px #022e50 solid !important;
}

.col-main-page.col-main-page-detail .content .col-talk form .btn.btn-primary {
  margin-top: 30px;
  background: #ffffff !important;
  border: 1px #cccccc solid;
  color: #022e50;
  border-top-color: rgb(2, 46, 80);
  border-right-color: rgb(2, 46, 80);
  border-bottom-color: rgb(2, 46, 80);
  border-left-color: rgb(2, 46, 80);
  font-size: 14px;
  font-weight: 600;
}

.col-main-page.col-main-page-detail .content.pages-contacts {
  padding-bottom: 60px;
}

.col-main-page.col-main-page-detail.pages-ol.new-pages-ol {
  padding-bottom: 60px;
}

.content-ol-for-col-talk .content {
  padding-top: 0px;
  z-index: 9;
}

.col-main-page.col-main-page-detail.col-detail-for-faq .content {
  margin-bottom: 0px !important;
  padding-bottom: 60px;
}

.col-main-page.col-main-page-detail .content.contents-pages-career {
  padding-bottom: 50px;
}

.card.cards-for-careers {
  border: 1px #e9e9e9 solid !important;
  border-radius: 12px !important;
}

.card.cards-for-careers .card-img-top.image-blogs {
  min-height: 150px;
}

.top-page-top-career.top-page-career.images-tops-career {
  border-radius: 12px;
}

.col-main-page.col-main-page-detail .content.contents-list-pages-careers {
  padding-bottom: 60px;
}

.top-page-detail.pages-details-for-careers {
  padding-top: 110px;
}

/*=====***** STYLE RESPONSIVE MOBILE *****=====*/

@media (min-width: 120px) and (max-width: 344px) {
  /*=====***** BACKGROUND BANNER *****=====*/

  .stage a .col-text {
    padding: 0 30px 45px 30px;
    font-size: 26px;
    line-height: 1.32;
    animation: titleAnimation 36s linear infinite 0s;
  }

  #stage .col-text h1 {
    font-size: 28px;
  }

  #stage .col-text p {
    font-size: 28px;
  }

  /*=====***** CODROPS *****=====*/

  .codrops-top {
    padding: 15px;
    height: 65px;
  }

  .codrops-top span img {
    max-width: 100px;
    top: -6px;
  }

  .codrops-top ul.top-nav-menu {
    display: none;
  }

  .codrops-top span.right {
    display: none;
  }

  .codrops-top span.right.display-mobile {
    display: inline-flex;
  }

  .codrops-top span.right.display-mobile a.link-free-consultation,
  .codrops-top span.right.display-mobile form {
    display: none;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu {
    display: inline-flex;
    padding-left: 15px;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu li {
    top: -7px;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu li a img {
    margin-right: 0px;
  }

  .codrops-top
    span.right.display-mobile
    ul.top-nav-menu
    li
    span
    .text-language {
    display: none;
  }

  .codrops-top span.right.display-mobile .home {
    margin-left: 15px;
  }

  .codrops-top span.right.display-mobile .home span img {
    top: -13px;
  }

  .codrops-top span.right.display-mobile span.btn-nav-mobile {
    margin-left: 15px;
    cursor: pointer;
  }

  .codrops-top
    span.right.display-mobile
    ul.top-nav-menu
    li
    ul.sub-nav-content
    li {
    top: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push {
    padding: 0px 15px;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 {
    margin: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 a {
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 15px;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 a:hover,
  .codrops-top .sidenav .col-bottom-nav-push h3 a:focus,
  .codrops-top .sidenav .col-bottom-nav-push h3 a:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push form {
    margin-bottom: 15px;
    display: none;
  }

  .codrops-top .sidenav .col-bottom-nav-push form .input-group .form-control {
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
    border-right: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .form-control:focus {
    box-shadow: none;
    border: 1px #02bee8 solid;
    border-right: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .input-group-text {
    background: none;
    border-left: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .input-group-text
    img {
    width: 14px;
    height: 14px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push {
    list-style: none;
    padding-left: 0px;
    margin: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    color: #a2acbd;
    font-weight: 700;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:hover,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:focus,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push {
    cursor: pointer !important;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    span.link-sub-nav {
    color: #a2acbd;
    font-weight: 700;
    /* cursor: default; */
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:active {
    color: #a2acbd;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content {
    list-style: none;
    padding-left: 20px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a {
    color: #a2acbd;
    font-weight: 700;
    text-transform: capitalize;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:active {
    color: #a2acbd;
    transition: 0.5s ease;
    cursor: default;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:active {
    color: #02bee8;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:active {
    color: #02bee8;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav {
    list-style: none;
  }

  .codrops-top .sidenav .col-top-nav-push span img {
    top: 0px;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    height: 50px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li h3 {
    font-size: 14px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li {
    text-transform: capitalize;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a {
    display: block;
    width: 100%;
    margin: 5px 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a {
    display: block;
    width: 100%;
    margin: 5px 0px;
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:hover,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:focus,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:active {
    color: #02bee8;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a {
    cursor: pointer !important;
  }

  .codrops-top span.right span.home img {
    top: -8px;
  }

  .codrops-top span.right ul.top-nav-menu li span img {
    margin-right: 0px;
  }

  .codrops-top span.right ul.top-nav-menu li ul.sub-nav-content {
    top: 35px;
  }

  /*=====***** TAB MENU ICON CKL *****=====*/

  .tab-menu-icon-ckl {
    bottom: 5px;
  }

  .tab-menu-icon-ckl button {
    max-width: 90px;
    margin: 0px 5px;
    max-height: 65px;
    min-height: 65px;
    margin-bottom: 0px;
    margin-top: 5px;
  }

  .tab-menu-icon-ckl button.tablinks.tablinks-international {
    max-width: 90px;
  }

  .tab-menu-icon-ckl button .col-img {
    height: 30px;
    margin-bottom: 5px;
  }

  .tab-menu-icon-ckl button img {
    height: 33px !important;
    width: 33px !important;
  }

  .tab-menu-icon-ckl button p {
    font-size: 8px;
    font-weight: 600;
    position: relative;
    top: 0px;
    width: 90px;
    margin: 0px auto;
    text-align: center;
    height: 42px;
  }

  .tab-menu-icon-ckl button.tablinks.tablinks-international p {
    width: 90px;
  }

  /*=====***** COL-WHY-CHOOSE-US *****=====*/

  .page-nav-tabs-why-choose-us .col-content-why-choose-us {
    padding: 0px 0px 150px 0px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .col-box-why-choose-us {
    padding: 5px;
    margin-top: 65px;
    text-align: center;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us {
    width: 85px;
    margin: 0px 5px;
    margin-bottom: 0px;
    text-align: center;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us
    img {
    width: 70px;
    padding: 5px 10px !important;
    margin-bottom: 5px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .col-box-why-choose-us
    .btn-content-why-choose-us
    img {
    padding-top: 10px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us
    p {
    font-size: 8px;
    margin-bottom: 10px;
  }

  /*=====***** COL-COURIER-SERVICES *****=====*/

  .page-nav-tabs-courier-services .col-content-courier-services {
    padding: 0px 0px 100px 0px;
  }

  .page-nav-tabs-courier-services .col-content-courier-services h3 {
    font-size: 16px;
    margin-bottom: 10px;
    padding-left: 0px;
    text-align: center;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services {
    width: 85px;
    margin: 0px 5px;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services
    img {
    width: 70px;
    padding: 20px 15px;
    margin-bottom: 2px;
    height: 70px;
    margin: 0px auto;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services
    p {
    font-size: 10px;
    margin-bottom: 5px;
    justify-content: center;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  /*=====***** COL-INTERNATIONAL-FREIGHT-FORWARDING *****=====*/

  .page-nav-tabs-international .col-content-international {
    padding: 0px 0px 100px 0px;
  }

  .page-nav-tabs-international .col-content-international h3 {
    font-size: 20px;
    margin-bottom: 15px;
    padding-left: 0px;
    text-align: center;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international {
    width: 80px;
    margin: 0px 5px;
    margin-bottom: 0px;
    justify-content: center;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international
    img {
    width: 65px;
    padding: 20px 15px;
    margin-bottom: 2px;
    height: 65px;
    margin: 0px auto;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international
    p {
    font-size: 8px;
    margin-bottom: 2px;
    display: flex;
    justify-content: center;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-international .col-content-international .col-btn-talk {
    margin-top: 5px;
  }

  .page-nav-tabs-international
    .col-content-international
    .col-btn-talk
    .btn-talk {
    padding: 5px 15px;
    height: 40px;
    margin: 0px 2px;
    width: auto;
  }

  .page-nav-tabs-international
    .col-content-international
    .col-btn-talk
    .btn-talk
    img {
    width: 100%;
    max-width: 20px;
    margin-right: 5px;
    height: 20px;
  }

  .page-nav-tabs-international
    .col-content-international
    .col-btn-talk
    .btn-talk
    span {
    font-size: 12px;
    top: 0px;
  }

  /*=====***** COL-SUPPLY-CHAIN *****=====*/

  .page-nav-tabs-supply .col-content-supply {
    padding: 0px 0px 100px 0px;
  }

  .page-nav-tabs-supply .col-content-supply h3 {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.42px;
    text-align: center;
    font-family: "IBM Plex Sans", sans-serif;
    transition: transform 0.5s ease;
    margin-bottom: 10px;
  }

  .page-nav-tabs-supply .col-content-supply p {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply {
    width: 50%;
    margin: 0px 5px;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply img {
    width: 65px;
    padding: 20px 15px;
    margin-bottom: 2px;
    height: 65px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply p {
    font-size: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  /*=====***** COL-OUR-NETWORK *****=====*/

  .page-nav-tabs-our-network .col-content-our-network {
    position: relative;
    text-align: center;
    padding: 5rem 0px 0 0px;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    display: block;
  }

  .page-nav-tabs-our-network
    .col-content-our-network
    .box-our-network
    h3.title-office {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.42px;
    text-align: left;
    padding: 20px 0px 0px 20px;
    margin-bottom: 15px;
  }

  .page-nav-tabs-our-network
    .col-content-our-network
    .box-our-network
    h4.title-city {
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.42px;
    font-weight: 700;
    text-align: left;
    padding: 0px 0px 0px 20px;
    margin-bottom: 15px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network ul {
    padding-left: 35px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network ul li {
    margin-bottom: 5px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network iframe {
    min-height: 160px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  /*=====***** COL-CHAT *****=====*/

  .page-nav-tabs-chat .col-content-chat {
    padding: 0px 0px 100px 0px;
  }

  .page-nav-tabs-chat .col-content-chat h3 {
    color: #ffffff;
    font-size: 12px !important;
    font-weight: 600;
    line-height: 1.42;
    letter-spacing: 0.42px;
    text-align: center;
    font-family: "IBM Plex Sans", sans-serif;
    transition: transform 0.5s ease;
    margin-bottom: 15px;
    padding-left: 0px;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk {
    margin-top: 5px;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk {
    padding: 5px 15px;
    height: 40px;
    margin: 0px 2px;
    width: auto;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk img {
    width: 100%;
    max-width: 20px;
    margin-right: 5px;
    height: 20px;
    display: none;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk span {
    font-size: 12px;
    top: 0px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network {
    display: inline-flex;
    overflow: scroll;
    width: 100%;
    height: 250px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network #vmap {
    margin-left: 30px !important;
  }

  .page-nav-tabs-chat .col-content-chat p {
    font-size: 12px !important;
    margin-bottom: 10px !important;
  }

  .content img.image-blogs-detail {
    height: 180px;
  }
}

@media (min-width: 345px) and (max-width: 479px) {
  /*=====***** BACKGROUND BANNER *****=====*/

  .stage a .col-text {
    padding: 0 30px 45px 30px;
    font-size: 26px;
    line-height: 1.32;
    animation: titleAnimation 36s linear infinite 0s;
  }

  #stage .col-text h1 {
    font-size: 28px;
  }

  #stage .col-text p {
    font-size: 28px;
  }

  /*=====***** CODROPS *****=====*/

  .codrops-top {
    padding: 15px;
    height: 65px;
  }

  .codrops-top span img {
    max-width: 100px;
    top: -6px;
  }

  .codrops-top ul.top-nav-menu {
    display: none;
  }

  .codrops-top span.right {
    display: none;
  }

  .codrops-top span.right.display-mobile {
    display: inline-flex;
  }

  .codrops-top span.right.display-mobile a.link-free-consultation,
  .codrops-top span.right.display-mobile form {
    display: none;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu {
    display: inline-flex;
    padding-left: 15px;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu li {
    top: -7px;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu li a img {
    margin-right: 0px;
  }

  .codrops-top
    span.right.display-mobile
    ul.top-nav-menu
    li
    span
    .text-language {
    display: none;
  }

  .codrops-top span.right.display-mobile .home {
    margin-left: 15px;
  }

  .codrops-top span.right.display-mobile .home span img {
    top: -13px;
  }

  .codrops-top span.right.display-mobile span.btn-nav-mobile {
    margin-left: 15px;
    cursor: pointer;
  }

  .codrops-top
    span.right.display-mobile
    ul.top-nav-menu
    li
    ul.sub-nav-content
    li {
    top: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push {
    padding: 0px 15px;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 {
    margin: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 a {
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 15px;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 a:hover,
  .codrops-top .sidenav .col-bottom-nav-push h3 a:focus,
  .codrops-top .sidenav .col-bottom-nav-push h3 a:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push form {
    margin-bottom: 15px;
    display: none;
  }

  .codrops-top .sidenav .col-bottom-nav-push form .input-group .form-control {
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
    border-right: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .form-control:focus {
    box-shadow: none;
    border: 1px #02bee8 solid;
    border-right: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .input-group-text {
    background: none;
    border-left: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .input-group-text
    img {
    width: 14px;
    height: 14px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push {
    list-style: none;
    padding-left: 0px;
    margin: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    color: #a2acbd;
    font-weight: 700;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:hover,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:focus,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push {
    cursor: pointer !important;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    span.link-sub-nav {
    color: #a2acbd;
    font-weight: 700;
    /* cursor: default; */
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:active {
    color: #a2acbd;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content {
    list-style: none;
    padding-left: 20px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a {
    color: #a2acbd;
    font-weight: 700;
    text-transform: capitalize;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:active {
    color: #a2acbd;
    transition: 0.5s ease;
    cursor: default;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:active {
    color: #02bee8;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:active {
    color: #02bee8;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav {
    list-style: none;
  }

  .codrops-top .sidenav .col-top-nav-push span img {
    top: 0px;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    height: 50px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li h3 {
    font-size: 14px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li {
    text-transform: capitalize;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a {
    display: block;
    width: 100%;
    margin: 5px 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a {
    display: block;
    width: 100%;
    margin: 5px 0px;
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:hover,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:focus,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:active {
    color: #02bee8;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a {
    cursor: pointer !important;
  }

  /*=====***** TAB MENU ICON CKL *****=====*/

  .tab-menu-icon-ckl {
    bottom: 0px;
  }

  .tab-menu-icon-ckl button {
    max-width: 105px;
    margin: 0px 5px;
    max-height: 80px;
    min-height: 80px;
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .tab-menu-icon-ckl button.tablinks.tablinks-international {
    max-width: 105px;
  }

  .tab-menu-icon-ckl button .col-img {
    height: 30px;
    margin-bottom: 5px;
  }

  .tab-menu-icon-ckl button img {
    height: 33px !important;
    width: 33px !important;
  }

  .tab-menu-icon-ckl button p {
    font-size: 10px;
    font-weight: 600;
    position: relative;
    top: 0px;
    width: 105px;
    margin: 0px auto;
    text-align: center;
    height: 42px;
  }

  .tab-menu-icon-ckl button.tablinks.tablinks-international p {
    width: 105px;
  }

  .codrops-top span.right span.home img {
    top: -8px;
  }

  .codrops-top span.right ul.top-nav-menu li span img {
    margin-right: 0px;
  }

  .codrops-top span.right ul.top-nav-menu li ul.sub-nav-content {
    top: 35px;
  }

  /*=====***** COL-WHY-CHOOSE-US *****=====*/

  .page-nav-tabs-why-choose-us .col-content-why-choose-us {
    padding: 0px 0px 150px 0px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .col-box-why-choose-us {
    padding: 5px;
    margin-top: 65px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us {
    width: 85px;
    margin: 0px 5px;
    margin-bottom: 0px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us
    img {
    width: 70px;
    padding: 5px 10px !important;
    margin-bottom: 5px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .col-box-why-choose-us
    .btn-content-why-choose-us
    img {
    padding-top: 10px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us
    p {
    font-size: 8px;
    margin-bottom: 10px;
  }

  /*=====***** COL-COURIER-SERVICES *****=====*/

  .page-nav-tabs-courier-services .col-content-courier-services {
    padding: 0px 0px 120px 0px;
  }

  .page-nav-tabs-courier-services .col-content-courier-services h3 {
    font-size: 20px;
    margin-bottom: 15px;
    padding-left: 0px;
    text-align: center;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services {
    width: 95px;
    margin: 0px 5px;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services
    img {
    width: 84px;
    padding: 25px 20px 25px 20px;
    margin-bottom: 5px;
    height: 80px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services
    p {
    font-size: 10px;
    margin-bottom: 5px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services {
    width: 80px;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services
    img {
    width: 65px;
    padding: 15px 5px;
    margin-bottom: 5px;
    height: 65px;
  }

  /*=====***** COL-INTERNATIONAL-FREIGHT-FORWARDING *****=====*/

  .page-nav-tabs-international .col-content-international {
    padding: 0px 0px 100px 0px;
  }

  .page-nav-tabs-international .col-content-international h3 {
    font-size: 20px;
    margin-bottom: 15px;
    padding-left: 0px;
    text-align: center;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international {
    width: 80px;
    margin: 0px 5px;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international
    img {
    width: 65px;
    padding: 20px 15px;
    margin-bottom: 2px;
    height: 65px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international
    p {
    font-size: 8px;
    margin-bottom: 2px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-international .col-content-international .col-btn-talk {
    margin-top: 5px;
  }

  .page-nav-tabs-international
    .col-content-international
    .col-btn-talk
    .btn-talk {
    padding: 5px 15px;
    height: 40px;
    margin: 0px 2px;
    width: auto;
  }

  .page-nav-tabs-international
    .col-content-international
    .col-btn-talk
    .btn-talk
    img {
    width: 100%;
    max-width: 20px;
    margin-right: 5px;
    height: 20px;
  }

  .page-nav-tabs-international
    .col-content-international
    .col-btn-talk
    .btn-talk
    span {
    font-size: 12px;
    top: 0px;
  }

  /*=====***** COL-SUPPLY-CHAIN *****=====*/

  .page-nav-tabs-supply .col-content-supply {
    padding: 0px 0px 100px 0px;
  }

  .page-nav-tabs-supply .col-content-supply h3 {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.42px;
    text-align: center;
    font-family: "IBM Plex Sans", sans-serif;
    transition: transform 0.5s ease;
    margin-bottom: 10px;
  }

  .page-nav-tabs-supply .col-content-supply p {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply {
    width: 50%;
    margin: 0px 5px;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply img {
    width: 65px;
    padding: 20px 15px;
    margin-bottom: 2px;
    height: 65px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply p {
    font-size: 10px;
    margin-bottom: 10px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  /*=====***** COL-OUR-NETWORK *****=====*/

  .page-nav-tabs-our-network .col-content-our-network {
    position: relative;
    text-align: center;
    padding: 6rem 0px 0 0px;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    display: block;
  }

  .page-nav-tabs-our-network
    .col-content-our-network
    .box-our-network
    h3.title-office {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.42px;
    text-align: left;
    padding: 20px 0px 0px 20px;
    margin-bottom: 15px;
  }

  .page-nav-tabs-our-network
    .col-content-our-network
    .box-our-network
    h4.title-city {
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.42px;
    font-weight: 700;
    text-align: left;
    padding: 0px 0px 0px 20px;
    margin-bottom: 15px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network ul {
    padding-left: 35px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network ul li {
    margin-bottom: 5px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network iframe {
    min-height: 160px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  /*=====***** COL-CHAT *****=====*/

  .page-nav-tabs-chat .col-content-chat {
    padding: 0px 0px 120px 0px;
  }

  .page-nav-tabs-chat .col-content-chat h3 {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.42;
    letter-spacing: 0.42px;
    text-align: center;
    font-family: "IBM Plex Sans", sans-serif;
    transition: transform 0.5s ease;
    margin-bottom: 15px;
    padding-left: 0px;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk {
    margin-top: 10px;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk {
    padding: 5px 15px;
    height: 40px;
    margin: 2px 0px;
    max-width: 100%;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk img {
    width: 100%;
    max-width: 20px;
    margin-right: 5px;
    height: 20px;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk span {
    font-size: 12px;
    top: 0px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network {
    display: inline-flex;
    overflow: scroll;
    width: 100%;
    height: 250px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network #vmap {
    margin-left: 30px !important;
  }

  .page-nav-tabs-chat .col-content-chat p {
    font-size: 14px !important;
  }

  .content img.image-blogs-detail {
    height: 210px;
  }
}

@media (min-width: 414px) and (max-width: 479px) {
  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .col-box-why-choose-us {
    padding: 5px 38px;
    margin-top: 65px;
  }
}

@media (min-width: 460px) and (max-width: 479px) {
  .tab-menu-icon-ckl {
    bottom: 0px;
    padding: 0px 55px;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  /*=====***** BACKGROUND BANNER *****=====*/

  .stage a .col-text {
    padding: 0 30px 45px 30px;
    font-size: 28px;
    line-height: 1.32;
    animation: titleAnimation 36s linear infinite 0s;
  }

  #stage .col-text h1 {
    font-size: 32px;
  }

  #stage .col-text p {
    font-size: 32px;
  }

  /*=====***** CODROPS *****=====*/

  .codrops-top {
    padding: 15px;
    height: 65px;
  }

  .codrops-top span img {
    max-width: 100px;
    top: -6px;
  }

  .codrops-top ul.top-nav-menu {
    display: none;
  }

  .codrops-top span.right {
    display: none;
  }

  .codrops-top span.right.display-mobile {
    display: inline-flex;
  }

  .codrops-top span.right.display-mobile a.link-free-consultation,
  .codrops-top span.right.display-mobile form {
    display: none;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu {
    display: inline-flex;
    padding-left: 15px;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu li {
    top: -7px;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu li a img {
    margin-right: 0px;
  }

  .codrops-top
    span.right.display-mobile
    ul.top-nav-menu
    li
    span
    .text-language {
    display: none;
  }

  .codrops-top span.right.display-mobile .home {
    margin-left: 15px;
  }

  .codrops-top span.right.display-mobile .home span img {
    top: -13px;
  }

  .codrops-top span.right.display-mobile span.btn-nav-mobile {
    margin-left: 15px;
    cursor: pointer;
  }

  .codrops-top
    span.right.display-mobile
    ul.top-nav-menu
    li
    ul.sub-nav-content
    li {
    top: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push {
    padding: 0px 15px;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 {
    margin: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 a {
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 15px;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 a:hover,
  .codrops-top .sidenav .col-bottom-nav-push h3 a:focus,
  .codrops-top .sidenav .col-bottom-nav-push h3 a:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push form {
    margin-bottom: 15px;
    display: none;
  }

  .codrops-top .sidenav .col-bottom-nav-push form .input-group .form-control {
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
    border-right: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .form-control:focus {
    box-shadow: none;
    border: 1px #02bee8 solid;
    border-right: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .input-group-text {
    background: none;
    border-left: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .input-group-text
    img {
    width: 14px;
    height: 14px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push {
    list-style: none;
    padding-left: 0px;
    margin: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    color: #a2acbd;
    font-weight: 700;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:hover,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:focus,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    span.link-sub-nav {
    color: #a2acbd;
    font-weight: 700;
    /* cursor: default; */
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:active {
    color: #a2acbd;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push {
    cursor: pointer !important;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content {
    list-style: none;
    padding-left: 20px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a {
    color: #a2acbd;
    font-weight: 700;
    text-transform: capitalize;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:active {
    color: #a2acbd;
    transition: 0.5s ease;
    cursor: default;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:active {
    color: #02bee8;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:active {
    color: #02bee8;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav {
    list-style: none;
  }

  .codrops-top span.right ul.top-nav-menu li span img {
    margin-right: 0px;
  }

  .codrops-top span.right span.home img {
    top: -8px;
  }

  .codrops-top .sidenav .col-top-nav-push span img {
    top: 0px;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    height: 50px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li h3 {
    font-size: 14px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li {
    text-transform: capitalize;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a {
    display: block;
    width: 100%;
    margin: 5px 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a {
    display: block;
    width: 100%;
    margin: 5px 0px;
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:hover,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:focus,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:active {
    color: #02bee8;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a {
    cursor: pointer !important;
  }

  /*=====***** TAB MENU ICON CKL *****=====*/

  .tab-menu-icon-ckl {
    bottom: 10px;
  }

  .tab-menu-icon-ckl button {
    max-width: 125px;
    margin: 0px 5px;
    max-height: 70px;
    min-height: 70px;
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .tab-menu-icon-ckl button.tablinks.tablinks-international {
    max-width: 125px;
  }

  .tab-menu-icon-ckl button .col-img {
    height: 30px;
    margin-bottom: 5px;
  }

  .tab-menu-icon-ckl button img {
    height: 33px !important;
    width: 33px !important;
  }

  .tab-menu-icon-ckl button p {
    font-size: 10px;
    font-weight: 600;
    position: relative;
    top: 0px;
    width: 105px;
    margin: 0px auto;
    text-align: center;
    height: 42px;
  }

  .tab-menu-icon-ckl button.tablinks.tablinks-international p {
    width: 105px;
  }

  /*=====***** COL-WHY-CHOOSE-US *****=====*/

  .page-nav-tabs-why-choose-us .col-content-why-choose-us {
    padding: 0px 60px 120px 60px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us {
    width: 95px;
    margin: 0px 5px;
    margin-bottom: 0px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us
    img {
    width: 70px;
    padding: 5px 10px !important;
    margin-bottom: 5px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .col-box-why-choose-us
    .btn-content-why-choose-us
    img {
    padding-top: 15px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us
    p {
    font-size: 10px;
    margin-bottom: 10px;
  }

  /*=====***** COL-COURIER-SERVICES *****=====*/

  .page-nav-tabs-courier-services .col-content-courier-services {
    padding: 0px 0px 120px 0px;
  }

  .page-nav-tabs-courier-services .col-content-courier-services h3 {
    font-size: 20px;
    margin-bottom: 15px;
    padding-left: 0px;
    text-align: center;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services {
    width: 95px;
    margin: 0px 5px;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services
    img {
    width: 84px;
    padding: 25px 15px 25px 15px;
    margin-bottom: 5px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services
    p {
    font-size: 10px;
    margin-bottom: 10px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  /*=====***** COL-INTERNATIONAL-FREIGHT-FORWARDING *****=====*/

  .page-nav-tabs-international .col-content-international {
    padding: 0px 60px 120px 60px;
  }

  .page-nav-tabs-international .col-content-international h3 {
    font-size: 20px;
    margin-bottom: 15px;
    padding-left: 0px;
    text-align: center;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international {
    width: 98px;
    margin: 0px 5px;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international
    img {
    width: 84px;
    padding: 25px 15px 25px 15px;
    margin-bottom: 5px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international
    p {
    font-size: 10px;
    margin-bottom: 10px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-international .col-content-international .col-btn-talk {
    margin-top: 10px;
  }

  .page-nav-tabs-international
    .col-content-international
    .col-btn-talk
    .btn-talk {
    padding: 5px 15px;
    height: 40px;
    margin: 2px 0px;
    max-width: 100%;
  }

  .page-nav-tabs-international
    .col-content-international
    .col-btn-talk
    .btn-talk
    img {
    width: 100%;
    max-width: 20px;
    margin-right: 5px;
    height: 20px;
  }

  .page-nav-tabs-international
    .col-content-international
    .col-btn-talk
    .btn-talk
    span {
    font-size: 12px;
    top: 0px;
  }

  /*=====***** COL-SUPPLY-CHAIN *****=====*/

  .page-nav-tabs-supply .col-content-supply {
    padding: 0px 30px 120px 30px;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply {
    width: 95px;
    margin: 0px 5px;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply img {
    width: 84px;
    padding: 25px 15px 25px 15px;
    margin-bottom: 5px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply p {
    font-size: 10px;
    margin-bottom: 10px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-supply .col-content-supply h3 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.42px;
    text-align: center;
    font-family: "IBM Plex Sans", sans-serif;
    transition: transform 0.5s ease;
    margin-bottom: 15px;
  }

  .page-nav-tabs-supply .col-content-supply p {
    font-size: 14px;
    font-weight: 500;
  }

  /*=====***** COL-OUR-NETWORK *****=====*/

  .page-nav-tabs-our-network .col-content-our-network {
    position: relative;
    text-align: center;
    padding: 6rem 0px 0 0px;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    display: block;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network {
    display: inline-flex;
    overflow: scroll;
    width: 100%;
    height: 300px;
  }

  .page-nav-tabs-our-network
    .col-content-our-network
    .box-our-network
    h3.title-office {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.42px;
    text-align: left;
    padding: 20px 0px 0px 20px;
    margin-bottom: 15px;
  }

  .page-nav-tabs-our-network
    .col-content-our-network
    .box-our-network
    h4.title-city {
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.42px;
    font-weight: 700;
    text-align: left;
    padding: 0px 0px 0px 20px;
    margin-bottom: 15px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network ul {
    padding-left: 35px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network ul li {
    margin-bottom: 5px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network iframe {
    min-height: 250px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  /*=====***** COL-CHAT *****=====*/

  .page-nav-tabs-chat .col-content-chat {
    padding: 0px 0px 120px 0px;
  }

  .page-nav-tabs-chat .col-content-chat h3 {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.42;
    letter-spacing: 0.42px;
    text-align: center;
    font-family: "IBM Plex Sans", sans-serif;
    transition: transform 0.5s ease;
    margin-bottom: 15px;
    padding-left: 0px;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk {
    margin-top: 10px;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk {
    padding: 5px 15px;
    height: 40px;
    margin: 2px 0px;
    max-width: 100%;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk img {
    width: 100%;
    max-width: 20px;
    margin-right: 5px;
    height: 20px;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk span {
    font-size: 12px;
    top: 0px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network {
    display: inline-flex;
    overflow: scroll;
    width: 100%;
    height: 280px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network #vmap {
    margin-left: 30px !important;
  }

  .page-nav-tabs-chat .col-content-chat p {
    font-size: 16px !important;
  }

  .content img.image-blogs-detail {
    height: 300px;
  }
}

@media (min-width: 460px) and (max-width: 639px) {
  .tab-menu-icon-ckl {
    bottom: 0px;
    padding: 0px 37px;
  }
}

@media (min-width: 564px) and (max-width: 575px) {
  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .col-box-why-choose-us {
    padding: 15px 15px;
  }
}

@media (min-width: 614px) and (max-width: 639px) {
  .tab-menu-icon-ckl {
    bottom: 0px;
    padding: 0px 65px;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  /*=====***** BACKGROUND BANNER *****=====*/

  .stage a .col-text {
    padding: 0 30px 45px 30px;
    font-size: 30px;
    line-height: 1.32;
    animation: titleAnimation 36s linear infinite 0s;
  }

  #stage .col-text h1 {
    font-size: 36px;
  }

  #stage .col-text p {
    font-size: 36px;
  }

  /*=====***** CODROPS *****=====*/

  .codrops-top {
    padding: 15px;
    height: 65px;
  }

  .codrops-top span img {
    max-width: 100px;
    top: -8px;
  }

  .codrops-top ul.top-nav-menu {
    display: none;
  }

  .codrops-top span.right {
    display: none;
  }

  .codrops-top span.right.display-mobile {
    display: inline-flex;
  }

  .codrops-top span.right.display-mobile a.link-free-consultation,
  .codrops-top span.right.display-mobile form {
    display: none;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu {
    display: inline-flex;
    padding-left: 15px;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu li {
    top: -7px;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu li a img {
    margin-right: 0px;
  }

  .codrops-top
    span.right.display-mobile
    ul.top-nav-menu
    li
    span
    .text-language {
    display: none;
  }

  .codrops-top span.right.display-mobile .home {
    margin-left: 15px;
  }

  .codrops-top span.right.display-mobile .home span img {
    top: -13px;
  }

  .codrops-top span.right.display-mobile span.btn-nav-mobile {
    margin-left: 15px;
    cursor: pointer;
  }

  .codrops-top
    span.right.display-mobile
    ul.top-nav-menu
    li
    ul.sub-nav-content
    li {
    top: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push {
    padding: 0px 15px;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 {
    margin: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 a {
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 15px;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 a:hover,
  .codrops-top .sidenav .col-bottom-nav-push h3 a:focus,
  .codrops-top .sidenav .col-bottom-nav-push h3 a:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push form {
    margin-bottom: 15px;
    display: none;
  }

  .codrops-top .sidenav .col-bottom-nav-push form .input-group .form-control {
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
    border-right: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .form-control:focus {
    box-shadow: none;
    border: 1px #02bee8 solid;
    border-right: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .input-group-text {
    background: none;
    border-left: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .input-group-text
    img {
    width: 14px;
    height: 14px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push {
    list-style: none;
    padding-left: 0px;
    margin: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    color: #a2acbd;
    font-weight: 700;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:hover,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:focus,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    span.link-sub-nav {
    color: #a2acbd;
    font-weight: 700;
    /* cursor: default; */
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:active {
    color: #a2acbd;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content {
    list-style: none;
    padding-left: 20px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a {
    color: #a2acbd;
    font-weight: 700;
    text-transform: capitalize;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:active {
    color: #a2acbd;
    transition: 0.5s ease;
    cursor: default;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:active {
    color: #02bee8;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:active {
    color: #02bee8;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push {
    cursor: pointer !important;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav {
    list-style: none;
  }

  .codrops-top span.right ul.top-nav-menu li span img {
    margin-right: 0px;
  }

  .codrops-top span.right span.home img {
    top: -8px;
  }

  .codrops-top span img {
    top: -6px;
  }

  .codrops-top span.right ul.top-nav-menu li ul.sub-nav-content {
    top: 35px;
  }

  .codrops-top .sidenav .col-top-nav-push span img {
    top: 0px;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    height: 50px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li h3 {
    font-size: 14px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li {
    text-transform: capitalize;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a {
    display: block;
    width: 100%;
    margin: 5px 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a {
    display: block;
    width: 100%;
    margin: 5px 0px;
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:hover,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:focus,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:active {
    color: #02bee8;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a {
    cursor: pointer !important;
  }

  /*=====***** TAB MENU ICON CKL *****=====*/

  .tab-menu-icon-ckl {
    bottom: 10px;
    padding: 0px 100px;
  }

  .tab-menu-icon-ckl button {
    max-width: 105px;
    margin: 0px 5px;
    max-height: 80px;
    min-height: 80px;
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .tab-menu-icon-ckl button.tablinks.tablinks-international {
    max-width: 105px;
  }

  .tab-menu-icon-ckl button .col-img {
    height: 30px;
    margin-bottom: 5px;
  }

  .tab-menu-icon-ckl button img {
    height: 33px !important;
    width: 33px !important;
  }

  .tab-menu-icon-ckl button p {
    font-size: 10px;
    font-weight: 600;
    position: relative;
    top: 0px;
    width: 105px;
    margin: 0px auto;
    text-align: center;
    height: 42px;
  }

  .tab-menu-icon-ckl button.tablinks.tablinks-international p {
    width: 105px;
  }

  /*=====***** COL-WHY-CHOOSE-US *****=====*/

  .page-nav-tabs-why-choose-us .col-content-why-choose-us {
    padding: 0px 0px 120px 0px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us {
    width: 95px;
    margin: 0px 5px;
    margin-bottom: 0px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us
    img {
    width: 70px;
    padding: 5px 10px !important;
    margin-bottom: 5px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .col-box-why-choose-us
    .btn-content-why-choose-us
    img {
    padding-top: 15px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us
    p {
    font-size: 10px;
    margin-bottom: 10px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .col-box-why-choose-us {
    padding: 15px 70px;
  }

  /*=====***** COL-COURIER-SERVICES *****=====*/

  .page-nav-tabs-courier-services .col-content-courier-services {
    padding: 0px 0px 120px 0px;
  }

  .page-nav-tabs-courier-services .col-content-courier-services h3 {
    font-size: 24px;
    margin-bottom: 15px;
    padding-left: 0px;
    text-align: center;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services {
    width: 95px;
    margin: 0px 5px;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services
    img {
    width: 90px;
    padding: 25px 18px !important;
    margin-bottom: 5px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services
    p {
    font-size: 10px;
    margin-bottom: 10px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  /*=====***** COL-INTERNATIONAL-FREIGHT-FORWARDING *****=====*/

  .page-nav-tabs-international .col-content-international {
    padding: 0px 60px 120px 60px;
  }

  .page-nav-tabs-international .col-content-international h3 {
    font-size: 24px;
    margin-bottom: 30px;
    padding-left: 0px;
    text-align: center;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international {
    width: 98px;
    margin: 0px 5px;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international
    img {
    width: 90px;
    padding: 25px 18px !important;
    margin-bottom: 5px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international
    p {
    font-size: 10px;
    margin-bottom: 10px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-international .col-content-international .col-btn-talk {
    margin-top: 10px;
  }

  .page-nav-tabs-international
    .col-content-international
    .col-btn-talk
    .btn-talk {
    padding: 5px 15px;
    height: 40px;
    margin: 2px 0px;
    max-width: 100%;
  }

  .page-nav-tabs-international
    .col-content-international
    .col-btn-talk
    .btn-talk
    img {
    width: 100%;
    max-width: 20px;
    margin-right: 5px;
    height: 20px;
  }

  .page-nav-tabs-international
    .col-content-international
    .col-btn-talk
    .btn-talk
    span {
    font-size: 12px;
    top: 0px;
  }

  /*=====***** COL-SUPPLY-CHAIN *****=====*/

  .page-nav-tabs-supply .col-content-supply {
    padding: 0px 30px 120px 30px;
  }

  .page-nav-tabs-supply .col-content-supply h3 {
    color: #ffffff;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.42px;
    text-align: center;
    font-family: "IBM Plex Sans", sans-serif;
    transition: transform 0.5s ease;
    margin-bottom: 15px;
  }

  .page-nav-tabs-supply .col-content-supply p {
    font-size: 14px;
    font-weight: 500;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply {
    width: 95px;
    margin: 0px 5px;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply img {
    width: 90px;
    padding: 25px 18px !important;
    margin-bottom: 5px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply p {
    font-size: 10px;
    margin-bottom: 10px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  /*=====***** COL-OUR-NETWORK *****=====*/

  .page-nav-tabs-our-network .col-content-our-network {
    position: relative;
    text-align: center;
    padding: 6rem 0px 0 0px;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    display: block;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network {
    display: inline-flex;
    overflow: scroll;
    width: 100%;
    height: 350px;
  }

  .page-nav-tabs-our-network
    .col-content-our-network
    .box-our-network
    h3.title-office {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.42px;
    text-align: left;
    padding: 20px 0px 0px 20px;
    margin-bottom: 15px;
  }

  .page-nav-tabs-our-network
    .col-content-our-network
    .box-our-network
    h4.title-city {
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.42px;
    font-weight: 700;
    text-align: left;
    padding: 0px 0px 0px 20px;
    margin-bottom: 15px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network ul {
    padding-left: 35px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network ul li {
    margin-bottom: 5px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network iframe {
    min-height: 250px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  /*=====***** COL-CHAT *****=====*/

  .page-nav-tabs-chat .col-content-chat {
    padding: 0px 0px 120px 0px;
  }

  .page-nav-tabs-chat .col-content-chat h3 {
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.42;
    letter-spacing: 0.42px;
    text-align: center;
    font-family: "IBM Plex Sans", sans-serif;
    transition: transform 0.5s ease;
    margin-bottom: 15px;
    padding-left: 0px;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk {
    margin-top: 10px;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk {
    padding: 5px 15px;
    height: 40px;
    margin: 2px 0px;
    max-width: 100%;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk img {
    width: 100%;
    max-width: 20px;
    margin-right: 5px;
    height: 20px;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk span {
    font-size: 12px;
    top: 0px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network {
    display: inline-flex;
    overflow: scroll;
    width: 100%;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network #vmap {
    margin-left: 30px !important;
  }

  .page-nav-tabs-chat .col-content-chat p {
    font-size: 18px !important;
  }
}

@media (min-width: 660px) and (max-width: 767px) {
  .tab-menu-icon-ckl {
    bottom: 0px;
    padding: 0px 155px;
  }
}

@media (min-width: 768px) and (max-width: 851px) {
  /*=====***** BACKGROUND BANNER *****=====*/

  .stage a .col-text {
    padding: 0 30px 45px 30px;
    font-size: 36px;
    line-height: 1.32;
    animation: titleAnimation 36s linear infinite 0s;
  }

  #stage .col-text h1 {
    font-size: 40px;
  }

  #stage .col-text p {
    font-size: 40px;
  }

  /*=====***** CODROPS *****=====*/

  .codrops-top {
    padding: 15px;
    height: 65px;
  }

  .codrops-top span img {
    max-width: 100px;
    top: -8px;
  }

  .codrops-top ul.top-nav-menu {
    display: none;
  }

  .codrops-top span.right {
    display: none;
  }

  .codrops-top span.right.display-mobile {
    display: inline-flex;
  }

  .codrops-top span.right.display-mobile a.link-free-consultation,
  .codrops-top span.right.display-mobile form {
    display: none;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu {
    display: inline-flex;
    padding-left: 15px;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu li {
    top: -7px;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu li a img {
    margin-right: 0px;
  }

  .codrops-top
    span.right.display-mobile
    ul.top-nav-menu
    li
    span
    .text-language {
    display: none;
  }

  .codrops-top span.right.display-mobile .home {
    margin-left: 15px;
  }

  .codrops-top span.right.display-mobile .home span img {
    top: -13px;
  }

  .codrops-top span.right.display-mobile span.btn-nav-mobile {
    margin-left: 15px;
    cursor: pointer;
  }

  .codrops-top
    span.right.display-mobile
    ul.top-nav-menu
    li
    ul.sub-nav-content
    li {
    top: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push {
    padding: 0px 15px;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 {
    margin: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 a {
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 15px;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 a:hover,
  .codrops-top .sidenav .col-bottom-nav-push h3 a:focus,
  .codrops-top .sidenav .col-bottom-nav-push h3 a:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push form {
    margin-bottom: 15px;
    display: none;
  }

  .codrops-top .sidenav .col-bottom-nav-push form .input-group .form-control {
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
    border-right: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .form-control:focus {
    box-shadow: none;
    border: 1px #02bee8 solid;
    border-right: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .input-group-text {
    background: none;
    border-left: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .input-group-text
    img {
    width: 14px;
    height: 14px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push {
    list-style: none;
    padding-left: 0px;
    margin: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    color: #a2acbd;
    font-weight: 700;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:hover,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:focus,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    span.link-sub-nav {
    color: #a2acbd;
    font-weight: 700;
    /* cursor: default; */
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:active {
    color: #a2acbd;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content {
    list-style: none;
    padding-left: 20px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a {
    color: #a2acbd;
    font-weight: 700;
    text-transform: capitalize;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:active {
    color: #a2acbd;
    transition: 0.5s ease;
    cursor: default;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:active {
    color: #02bee8;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:active {
    color: #02bee8;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push {
    cursor: pointer !important;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav {
    list-style: none;
  }

  .codrops-top span.right ul.top-nav-menu li span img {
    margin-right: 0px;
  }

  .codrops-top span.right span.home img {
    top: -8px;
  }

  .codrops-top span img {
    top: -6px;
  }

  .codrops-top span.right ul.top-nav-menu li ul.sub-nav-content {
    top: 35px;
  }

  .codrops-top .sidenav .col-top-nav-push span img {
    top: 0px;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    height: 50px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li h3 {
    font-size: 14px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li {
    text-transform: capitalize;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a {
    display: block;
    width: 100%;
    margin: 5px 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a {
    display: block;
    width: 100%;
    margin: 5px 0px;
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:hover,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:focus,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:active {
    color: #02bee8;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a {
    cursor: pointer !important;
  }

  /*=====***** TAB MENU ICON CKL *****=====*/

  .tab-menu-icon-ckl {
    bottom: 15px;
  }

  .tab-menu-icon-ckl button {
    max-width: 105px;
    margin: 0px 5px;
    max-height: 80px;
    min-height: 80px;
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .tab-menu-icon-ckl button .col-img {
    height: 30px;
    margin-bottom: 5px;
  }

  .tab-menu-icon-ckl button img {
    height: 33px !important;
    width: 33px !important;
  }

  .tab-menu-icon-ckl button p {
    font-size: 10px;
    font-weight: 600;
    position: relative;
    top: 0px;
    width: 105px;
    margin: 0px auto;
    text-align: center;
    height: 42px;
  }

  /*=====***** COL-WHY-CHOOSE-US *****=====*/

  .page-nav-tabs-why-choose-us .col-content-why-choose-us {
    padding: 0px 0px 60px 0px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us {
    width: 110px;
    margin: 0px 10px;
    margin-bottom: 0px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us
    img {
    width: 70px;
    padding: 5px 10px !important;
    margin-bottom: 10px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .col-box-why-choose-us
    .btn-content-why-choose-us
    img {
    padding-top: 15px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us
    p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  /*=====***** COL-COURIER-SERVICES *****=====*/

  .page-nav-tabs-courier-services .col-content-courier-services {
    padding: 0px 30px 60px 30px;
  }

  .page-nav-tabs-courier-services .col-content-courier-services h3 {
    font-size: 24px;
    margin-bottom: 30px;
    padding-left: 0px;
    text-align: center;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services {
    width: 110px;
    margin: 0px 10px;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services
    img {
    width: 84px;
    padding: 25px 15px 25px 15px;
    margin-bottom: 10px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services
    p {
    font-size: 12px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  /*=====***** COL-INTERNATIONAL-FREIGHT-FORWARDING *****=====*/

  .page-nav-tabs-international .col-content-international {
    padding: 0px 30px 60px 30px;
  }

  .page-nav-tabs-international .col-content-international h3 {
    font-size: 24px;
    margin-bottom: 15px;
    padding-left: 0px;
    text-align: center;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international {
    width: 110px;
    margin: 0px 10px;
    margin-bottom: 0px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international
    img {
    width: 84px;
    padding: 25px 15px 25px 15px;
    margin-bottom: 10px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international
    p {
    font-size: 12px;
    margin-bottom: 10px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-international .col-content-international .col-btn-talk {
    margin-top: 10px;
  }

  .page-nav-tabs-international
    .col-content-international
    .col-btn-talk
    .btn-talk {
    padding: 5px 15px;
    height: 46px;
    margin: 2px;
  }

  .page-nav-tabs-international
    .col-content-international
    .col-btn-talk
    .btn-talk
    img {
    width: 100%;
    max-width: 22px;
    margin-right: 5px;
    height: 22px;
  }

  .page-nav-tabs-international
    .col-content-international
    .col-btn-talk
    .btn-talk
    span {
    font-size: 12px;
    top: 0px;
  }

  /*=====***** COL-SUPPLY-CHAIN *****=====*/

  .page-nav-tabs-supply .col-content-supply {
    padding: 0px 30px 60px 30px;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply {
    width: 110px;
    margin: 0px 10px;
    margin-bottom: 0px;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply img {
    width: 84px;
    padding: 25px 15px 25px 15px;
    margin-bottom: 10px;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply p {
    font-size: 12px;
    margin-bottom: 10px;

    display: flex;
    justify-content: center;
  }

  /*=====***** COL-OUR-NETWORK *****=====*/

  .page-nav-tabs-our-network .col-content-our-network {
    position: relative;
    text-align: center;
    padding: 6rem 0px 0 0px;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    display: block;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network {
    display: inline-flex;
    overflow: scroll;
    width: 100%;
  }

  .page-nav-tabs-our-network
    .col-content-our-network
    .box-our-network
    h3.title-office {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.42px;
    text-align: left;
    padding: 20px 0px 0px 20px;
    margin-bottom: 15px;
  }

  .page-nav-tabs-our-network
    .col-content-our-network
    .box-our-network
    h4.title-city {
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.42px;
    font-weight: 700;
    text-align: left;
    padding: 0px 0px 0px 20px;
    margin-bottom: 15px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network ul {
    padding-left: 35px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network ul li {
    margin-bottom: 5px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network iframe {
    min-height: 250px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  /*=====***** COL-CHAT *****=====*/

  .page-nav-tabs-chat .col-content-chat {
    padding: 0px 0px 60px 0px;
  }

  .page-nav-tabs-chat .col-content-chat h3 {
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.42;
    letter-spacing: 0.42px;
    text-align: center;
    font-family: "IBM Plex Sans", sans-serif;
    transition: transform 0.5s ease;
    margin-bottom: 15px;
    padding-left: 0px;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk {
    margin-top: 10px;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk {
    padding: 5px 15px;
    height: 46px;
    margin: 2px;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk img {
    width: 100%;
    max-width: 22px;
    margin-right: 5px;
    height: 22px;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk .btn-talk span {
    font-size: 12px;
    top: 0px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network {
    display: inline-flex;
    overflow: scroll;
    width: 100%;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network #vmap {
    margin-left: 30px !important;
  }

  .card.cards-for-careers .card-img-top.image-blogs {
    min-height: 100px;
  }
}

@media (min-width: 852px) and (max-width: 991px) {
  /*=====***** BACKGROUND BANNER *****=====*/

  .stage a .col-text {
    padding: 0 60px 45px 60px;
    font-size: 36px;
    line-height: 1.32;
    animation: titleAnimation 36s linear infinite 0s;
  }

  #stage .col-text h1 {
    font-size: 40px;
  }

  #stage .col-text p {
    font-size: 40px;
  }

  /*=====***** CODROPS *****=====*/

  .codrops-top {
    padding: 15px;
    height: 65px;
  }

  .codrops-top span img {
    max-width: 100px;
    top: -8px;
  }

  .codrops-top ul.top-nav-menu {
    display: none;
  }

  .codrops-top span.right {
    display: none;
  }

  .codrops-top span.right.display-mobile {
    display: inline-flex;
  }

  .codrops-top span.right.display-mobile a.link-free-consultation,
  .codrops-top span.right.display-mobile form {
    display: none;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu {
    display: inline-flex;
    padding-left: 15px;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu li {
    top: -7px;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu li a img {
    margin-right: 0px;
  }

  .codrops-top
    span.right.display-mobile
    ul.top-nav-menu
    li
    span
    .text-language {
    display: none;
  }

  .codrops-top span.right.display-mobile .home {
    margin-left: 15px;
  }

  .codrops-top span.right.display-mobile .home span img {
    top: -13px;
  }

  .codrops-top span.right.display-mobile span.btn-nav-mobile {
    margin-left: 15px;
    cursor: pointer;
  }

  .codrops-top
    span.right.display-mobile
    ul.top-nav-menu
    li
    ul.sub-nav-content
    li {
    top: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push {
    padding: 0px 15px;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 {
    margin: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 a {
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 15px;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 a:hover,
  .codrops-top .sidenav .col-bottom-nav-push h3 a:focus,
  .codrops-top .sidenav .col-bottom-nav-push h3 a:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push form {
    margin-bottom: 15px;
    display: none;
  }

  .codrops-top .sidenav .col-bottom-nav-push form .input-group .form-control {
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
    border-right: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .form-control:focus {
    box-shadow: none;
    border: 1px #02bee8 solid;
    border-right: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .input-group-text {
    background: none;
    border-left: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .input-group-text
    img {
    width: 14px;
    height: 14px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push {
    list-style: none;
    padding-left: 0px;
    margin: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    color: #a2acbd;
    font-weight: 700;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:hover,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:focus,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    span.link-sub-nav {
    color: #a2acbd;
    font-weight: 700;
    /* cursor: default; */
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:active {
    color: #a2acbd;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content {
    list-style: none;
    padding-left: 20px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a {
    color: #a2acbd;
    font-weight: 700;
    text-transform: capitalize;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:active {
    color: #a2acbd;
    transition: 0.5s ease;
    cursor: default;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:active {
    color: #02bee8;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:active {
    color: #02bee8;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push {
    cursor: pointer !important;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav {
    list-style: none;
  }

  .codrops-top span.right ul.top-nav-menu li span img {
    margin-right: 0px;
  }

  .codrops-top span.right span.home img {
    top: -8px;
  }

  .codrops-top span img {
    top: -6px;
  }

  .codrops-top span.right ul.top-nav-menu li ul.sub-nav-content {
    top: 35px;
  }

  .codrops-top .sidenav .col-top-nav-push span img {
    top: 0px;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    height: 50px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li h3 {
    font-size: 14px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li {
    text-transform: capitalize;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a {
    display: block;
    width: 100%;
    margin: 5px 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a {
    display: block;
    width: 100%;
    margin: 5px 0px;
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:hover,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:focus,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:active {
    color: #02bee8;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a {
    cursor: pointer !important;
  }

  /*=====***** TAB MENU ICON CKL *****=====*/

  /*=====***** TAB MENU ICON CKL *****=====*/

  .tab-menu-icon-ckl {
    bottom: 15px;
  }

  .tab-menu-icon-ckl button {
    max-width: 105px;
    margin: 0px 5px;
    max-height: 80px;
    min-height: 80px;
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .tab-menu-icon-ckl button .col-img {
    height: 30px;
    margin-bottom: 5px;
  }

  .tab-menu-icon-ckl button img {
    height: 33px !important;
    width: 33px !important;
  }

  .tab-menu-icon-ckl button p {
    font-size: 10px;
    font-weight: 600;
    position: relative;
    top: 0px;
    width: 105px;
    margin: 0px auto;
    text-align: center;
    height: 42px;
  }

  /*=====***** COL-WHY-CHOOSE-US *****=====*/

  .page-nav-tabs-why-choose-us .col-content-why-choose-us {
    padding: 0 0px 45px 0px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us {
    width: 110px;
    margin: 0px 10px;
    margin-bottom: 15px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .col-box-why-choose-us
    .btn-content-why-choose-us
    img {
    width: 70px;
    padding: 5px 10px !important;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us
    p {
    font-size: 12px;
    margin-bottom: 0px;
  }

  /*=====***** COL-COURIER-SERVICES *****=====*/

  .page-nav-tabs-courier-services .col-content-courier-services {
    padding: 0 0px 45px 0px;
  }

  .page-nav-tabs-courier-services .col-content-courier-services h3 {
    padding-left: 0px;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services {
    width: 110px;
    margin: 0px 10px;
    margin-bottom: 5px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services
    img {
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services
    p {
    font-size: 12px;
    margin-bottom: 10px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  /*=====***** COL-INTERNATIONAL-FREIGHT-FORWARDING *****=====*/

  .page-nav-tabs-international .col-content-international {
    padding: 0 30px 45px 30px;
  }

  .page-nav-tabs-international .col-content-international h3 {
    padding-left: 0px;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international {
    width: 110px;
    margin: 0px 10px;
    margin-bottom: 5px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international
    img {
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international
    p {
    font-size: 12px;
    margin-bottom: 10px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-international .col-content-international .col-btn-talk {
    margin-top: 5px;
  }

  /*=====***** COL-SUPPLY-CHAIN *****=====*/

  .page-nav-tabs-supply .col-content-supply {
    padding: 0 0px 45px 0px;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply {
    width: 110px;
    margin: 0px 10px;
    margin-bottom: 5px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply img {
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply p {
    font-size: 12px;
    margin-bottom: 10px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  /*=====***** COL-OUR-NETWORK *****=====*/

  .page-nav-tabs-our-network .col-content-our-network {
    position: relative;
    text-align: center;
    padding: 6rem 0px 0 0px;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    display: block;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network {
    display: inline-flex;
    overflow: scroll;
    width: 100%;
  }

  .page-nav-tabs-our-network
    .col-content-our-network
    .box-our-network
    h3.title-office {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.42px;
    text-align: left;
    padding: 20px 0px 0px 20px;
    margin-bottom: 15px;
  }

  .page-nav-tabs-our-network
    .col-content-our-network
    .box-our-network
    h4.title-city {
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.42px;
    font-weight: 700;
    text-align: left;
    padding: 0px 0px 0px 20px;
    margin-bottom: 15px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network ul {
    padding-left: 35px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network ul li {
    margin-bottom: 5px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network iframe {
    min-height: 400px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  /*=====***** COL-CHAT *****=====*/

  .page-nav-tabs-chat .col-content-chat {
    padding: 0 60px 45px 60px;
  }

  .page-nav-tabs-chat .col-content-chat h3 {
    padding-left: 0px;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk {
    margin-top: 10px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network {
    display: inline-flex;
    overflow: scroll;
    width: 100%;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network #vmap {
    margin-left: 30px !important;
  }

  .card.cards-for-careers .card-img-top.image-blogs {
    min-height: 100px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  /*=====***** BACKGROUND BANNER *****=====*/

  .stage a .col-text {
    padding: 0 90px 45px 90px;
    font-size: 38px;
    line-height: 1.32;
    animation: titleAnimation 36s linear infinite 0s;
  }

  #stage .col-text h1 {
    font-size: 40px;
  }

  #stage .col-text p {
    font-size: 40px;
  }

  /*=====***** CODROPS *****=====*/

  .codrops-top {
    padding: 15px;
    height: 65px;
  }

  .codrops-top span img {
    max-width: 100px;
    top: -8px;
  }

  .codrops-top ul.top-nav-menu {
    display: none;
  }

  .codrops-top span.right {
    display: none;
  }

  .codrops-top span.right.display-mobile {
    display: inline-flex;
  }

  .codrops-top span.right.display-mobile a.link-free-consultation,
  .codrops-top span.right.display-mobile form {
    display: none;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu {
    display: inline-flex;
    padding-left: 15px;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu li {
    top: -7px;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu li a img {
    margin-right: 0px;
  }

  .codrops-top
    span.right.display-mobile
    ul.top-nav-menu
    li
    span
    .text-language {
    display: none;
  }

  .codrops-top span.right.display-mobile .home {
    margin-left: 15px;
  }

  .codrops-top span.right.display-mobile .home span img {
    top: -13px;
  }

  .codrops-top span.right.display-mobile span.btn-nav-mobile {
    margin-left: 15px;
    cursor: pointer;
  }

  .codrops-top
    span.right.display-mobile
    ul.top-nav-menu
    li
    ul.sub-nav-content
    li {
    top: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push {
    padding: 0px 15px;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 {
    margin: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 a {
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 15px;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 a:hover,
  .codrops-top .sidenav .col-bottom-nav-push h3 a:focus,
  .codrops-top .sidenav .col-bottom-nav-push h3 a:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push form {
    margin-bottom: 15px;
    display: none;
  }

  .codrops-top .sidenav .col-bottom-nav-push form .input-group .form-control {
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
    border-right: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .form-control:focus {
    box-shadow: none;
    border: 1px #02bee8 solid;
    border-right: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .input-group-text {
    background: none;
    border-left: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .input-group-text
    img {
    width: 14px;
    height: 14px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push {
    list-style: none;
    padding-left: 0px;
    margin: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    color: #a2acbd;
    font-weight: 700;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:hover,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:focus,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    span.link-sub-nav {
    color: #a2acbd;
    font-weight: 700;
    /* cursor: default; */
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:active {
    color: #a2acbd;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content {
    list-style: none;
    padding-left: 20px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a {
    color: #a2acbd;
    font-weight: 700;
    text-transform: capitalize;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:active {
    color: #a2acbd;
    transition: 0.5s ease;
    cursor: default;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:active {
    color: #02bee8;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:active {
    color: #02bee8;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push {
    cursor: pointer !important;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav {
    list-style: none;
  }

  .codrops-top span.right ul.top-nav-menu li span img {
    margin-right: 0px;
  }

  .codrops-top span.right span.home img {
    top: -8px;
  }

  .codrops-top span img {
    top: -6px;
  }

  .codrops-top span.right ul.top-nav-menu li ul.sub-nav-content {
    top: 35px;
  }

  .codrops-top .sidenav .col-top-nav-push span img {
    top: 0px;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    height: 50px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li h3 {
    font-size: 14px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li {
    text-transform: capitalize;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a {
    display: block;
    width: 100%;
    margin: 5px 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a {
    display: block;
    width: 100%;
    margin: 5px 0px;
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:hover,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:focus,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:active {
    color: #02bee8;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a {
    cursor: pointer !important;
  }

  /*=====***** TAB MENU ICON CKL *****=====*/

  .tab-menu-icon-ckl {
    bottom: 10px;
  }

  .tab-menu-icon-ckl button {
    margin: 0px 10px;
  }

  .tab-menu-icon-ckl button p {
    height: 50px;
    position: relative;
    top: 0;
    margin: 0px auto;
    text-align: center;
  }

  /*=====***** COL-WHY-CHOOSE-US *****=====*/

  .page-nav-tabs-why-choose-us .col-content-why-choose-us {
    padding: 0 90px 45px 90px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us {
    width: 110px;
    margin: 0px 10px;
    margin-bottom: 10px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us
    p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  /*=====***** COL-COURIER-SERVICES *****=====*/

  .page-nav-tabs-courier-services .col-content-courier-services {
    padding: 0 0px 45px 0px;
  }

  .page-nav-tabs-courier-services .col-content-courier-services h3 {
    padding-left: 0px;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services {
    width: 110px;
    margin: 0px 10px;
    margin-bottom: 10px;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services
    p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  /*=====***** COL-INTERNATIONAL-FREIGHT-FORWARDING *****=====*/

  .page-nav-tabs-international .col-content-international {
    padding: 0 150px 45px 150px;
  }

  .page-nav-tabs-international .col-content-international h3 {
    padding-left: 0px;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international {
    width: 110px;
    margin: 0px 10px;
    margin-bottom: 10px;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international
    p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .page-nav-tabs-international .col-content-international .col-btn-talk {
    margin-top: 10px;
  }

  /*=====***** COL-SUPPLY-CHAIN *****=====*/

  .page-nav-tabs-supply .col-content-supply {
    padding: 0 90px 45px 90px;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply {
    width: 110px;
    margin: 0px 10px;
    margin-bottom: 10px;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  /*=====***** COL-OUR-NETWORK *****=====*/

  .page-nav-tabs-our-network .col-content-our-network {
    position: relative;
    text-align: center;
    padding: 6rem 0px 0 0px;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    display: block;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network {
    display: inline-flex;
    overflow: scroll;
    width: 100%;
  }

  .page-nav-tabs-our-network
    .col-content-our-network
    .box-our-network
    h3.title-office {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.42px;
    text-align: left;
    padding: 20px 0px 0px 20px;
    margin-bottom: 15px;
  }

  .page-nav-tabs-our-network
    .col-content-our-network
    .box-our-network
    h4.title-city {
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.42px;
    font-weight: 700;
    text-align: left;
    padding: 0px 0px 0px 20px;
    margin-bottom: 15px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network ul {
    padding-left: 35px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network ul li {
    margin-bottom: 5px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network iframe {
    min-height: 400px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  /*=====***** COL-CHAT *****=====*/

  .page-nav-tabs-chat .col-content-chat {
    padding: 0 90px 45px 90px;
  }

  .page-nav-tabs-chat .col-content-chat h3 {
    padding-left: 0px;
  }

  .page-nav-tabs-chat .col-content-chat .col-btn-talk {
    margin-top: 10px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network {
    display: inline-flex;
    overflow: scroll;
    width: 100%;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network #vmap {
    margin-left: 30px !important;
  }

  .tab-menu-icon-ckl button img {
    height: 40px !important;
    width: 40px !important;
  }

  .card.cards-for-careers .card-img-top.image-blogs {
    min-height: 140px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  /*=====***** BACKGROUND BANNER *****=====*/

  .stage a .col-text {
    padding: 0 120px 45px 120px;
    font-size: 38px;
    line-height: 1.32;
    animation: titleAnimation 36s linear infinite 0s;
  }

  #stage .col-text h1 {
    font-size: 42px;
  }

  #stage .col-text p {
    font-size: 42px;
  }

  /*=====***** CODROPS *****=====*/

  .codrops-top {
    padding: 15px;
    height: 65px;
  }

  .codrops-top span img {
    max-width: 100px;
    top: -8px;
  }

  .codrops-top ul.top-nav-menu {
    display: none;
  }

  .codrops-top span.right {
    display: none;
  }

  .codrops-top span.right.display-mobile {
    display: inline-flex;
  }

  .codrops-top span.right.display-mobile a.link-free-consultation,
  .codrops-top span.right.display-mobile form {
    display: none;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu {
    display: inline-flex;
    padding-left: 15px;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu li {
    top: -7px;
  }

  .codrops-top span.right.display-mobile ul.top-nav-menu li a img {
    margin-right: 0px;
  }

  .codrops-top
    span.right.display-mobile
    ul.top-nav-menu
    li
    span
    .text-language {
    display: none;
  }

  .codrops-top span.right.display-mobile .home {
    margin-left: 15px;
  }

  .codrops-top span.right.display-mobile .home span img {
    top: -13px;
  }

  .codrops-top span.right.display-mobile span.btn-nav-mobile {
    margin-left: 15px;
    cursor: pointer;
  }

  .codrops-top
    span.right.display-mobile
    ul.top-nav-menu
    li
    ul.sub-nav-content
    li {
    top: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push {
    padding: 0px 15px;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 {
    margin: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 a {
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 15px;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push h3 a:hover,
  .codrops-top .sidenav .col-bottom-nav-push h3 a:focus,
  .codrops-top .sidenav .col-bottom-nav-push h3 a:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push form {
    margin-bottom: 15px;
    display: none;
  }

  .codrops-top .sidenav .col-bottom-nav-push form .input-group .form-control {
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
    border-right: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .form-control:focus {
    box-shadow: none;
    border: 1px #02bee8 solid;
    border-right: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .input-group-text {
    background: none;
    border-left: 0px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    form
    .input-group
    .input-group-text
    img {
    width: 14px;
    height: 14px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push {
    list-style: none;
    padding-left: 0px;
    margin: 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    color: #a2acbd;
    font-weight: 700;
    transition: 0.5s ease;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:hover,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:focus,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li span:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    span.link-sub-nav {
    color: #a2acbd;
    font-weight: 700;
    /* cursor: default; */
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    a.link-sub-nav:active {
    color: #a2acbd;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content {
    list-style: none;
    padding-left: 20px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a {
    color: #a2acbd;
    font-weight: 700;
    text-transform: capitalize;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a:active {
    color: #a2acbd;
    transition: 0.5s ease;
    cursor: default;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.a-link-subnav:active {
    color: #02bee8;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a:active {
    color: #02bee8;
    transition: 0.5s ease;
    cursor: pointer;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push {
    cursor: pointer !important;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:hover,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:focus,
  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a.link-iff-push:active {
    color: #02bee8;
    transition: 0.5s ease;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav {
    list-style: none;
  }

  .codrops-top span.right ul.top-nav-menu li span img {
    margin-right: 0px;
  }

  .codrops-top span.right span.home img {
    top: -8px;
  }

  .codrops-top span img {
    top: -6px;
  }

  .codrops-top span.right ul.top-nav-menu li ul.sub-nav-content {
    top: 35px;
  }

  .codrops-top .sidenav .col-top-nav-push span img {
    top: 0px;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    height: 50px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li h3 {
    font-size: 14px;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li {
    text-transform: capitalize;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    .subs-nav
    li
    a {
    display: block;
    width: 100%;
    margin: 5px 0px;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a {
    display: block;
    width: 100%;
    margin: 5px 0px;
    font-size: 14px;
    color: #a2acbd;
    font-weight: 700;
  }

  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:hover,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:focus,
  .codrops-top .sidenav .col-bottom-nav-push .nav-menu-push li a:active {
    color: #02bee8;
  }

  .codrops-top
    .sidenav
    .col-bottom-nav-push
    .nav-menu-push
    li
    .sub-nav-content
    li
    a {
    cursor: pointer !important;
  }

  /*=====***** TAB MENU ICON CKL *****=====*/

  .tab-menu-icon-ckl {
    bottom: 10px;
  }

  .tab-menu-icon-ckl button {
    margin: 0px 10px;
  }

  .tab-menu-icon-ckl button p {
    height: 50px;
    position: relative;
    top: 0;
    margin: 0px auto;
    text-align: center;
  }

  /*=====***** COL-WHY-CHOOSE-US *****=====*/

  .page-nav-tabs-why-choose-us .col-content-why-choose-us {
    padding: 0 90px 45px 90px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us {
    width: 110px;
    margin: 0px 10px;
    margin-bottom: 10px;
  }

  .page-nav-tabs-why-choose-us
    .col-content-why-choose-us
    .btn-content-why-choose-us
    p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  /*=====***** COL-COURIER-SERVICES *****=====*/

  .page-nav-tabs-courier-services .col-content-courier-services {
    padding: 0 0px 45px 0px;
  }

  .page-nav-tabs-courier-services .col-content-courier-services h3 {
    padding-left: 0px;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services {
    width: 110px;
    margin: 0px 10px;
    margin-bottom: 10px;
  }

  .page-nav-tabs-courier-services
    .col-content-courier-services
    .btn-content-courier-services
    p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  /*=====***** COL-INTERNATIONAL-FREIGHT-FORWARDING *****=====*/

  .page-nav-tabs-international .col-content-international {
    padding: 0 150px 45px 150px;
  }

  .page-nav-tabs-international .col-content-international h3 {
    padding-left: 0px;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international {
    width: 110px;
    margin: 0px 10px;
    margin-bottom: 10px;
  }

  .page-nav-tabs-international
    .col-content-international
    .btn-content-international
    p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .page-nav-tabs-international .col-content-international .col-btn-talk {
    margin-top: 10px;
  }

  /*=====***** COL-SUPPLY-CHAIN *****=====*/

  .page-nav-tabs-supply .col-content-supply {
    padding: 0 90px 45px 90px;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply {
    width: 110px;
    margin: 0px 10px;
    margin-bottom: 10px;
  }

  .page-nav-tabs-supply .col-content-supply .btn-content-supply p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  /*=====***** COL-OUR-NETWORK *****=====*/

  .page-nav-tabs-our-network .col-content-our-network {
    position: relative;
    text-align: center;
    padding: 6rem 0px 0 0px;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    display: block;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network {
    display: inline-flex;
    overflow: scroll;
    width: 100%;
  }

  .page-nav-tabs-our-network
    .col-content-our-network
    .box-our-network
    h3.title-office {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.42px;
    text-align: left;
    padding: 20px 0px 0px 20px;
    margin-bottom: 15px;
  }

  .page-nav-tabs-our-network
    .col-content-our-network
    .box-our-network
    h4.title-city {
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.42px;
    font-weight: 700;
    text-align: left;
    padding: 0px 0px 0px 20px;
    margin-bottom: 15px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network ul {
    padding-left: 35px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network ul li {
    margin-bottom: 5px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network iframe {
    min-height: 400px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  /*=====***** COL-CHAT *****=====*/

  .page-nav-tabs-chat .col-content-chat {
    padding: 0 90px 45px 90px;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network {
    display: inline-flex;
    overflow: scroll;
    width: 100%;
  }

  .page-nav-tabs-our-network .col-content-our-network .box-our-network #vmap {
    margin-left: 30px !important;
  }

  .tab-menu-icon-ckl button img {
    height: 42px !important;
    width: 42px !important;
  }

  .card.cards-for-careers .card-img-top.image-blogs {
    min-height: 140px;
  }
}

@media (min-width: 1200px) and (max-width: 1279px) {
  .tab-menu-icon-ckl button img {
    height: 46px !important;
    width: 46px !important;
  }

  .codrops-top span.right ul.top-nav-menu li ul.sub-nav-content {
    top: 35px;
  }

  .content img.image-blogs-detail {
    height: 450px;
  }
}

@media (min-width: 1280px) and (max-width: 1399px) {
  .content img.image-blogs-detail {
    height: 450px;
  }
}

@media only screen and (min-width: 1400px) {
  .content img.image-blogs-detail {
    height: 500px;
  }
}

.jqvmap-label {
  z-index: 999;
}

.jvectormap-tip {
  position: absolute;
  display: block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #292929;
  color: white;
  font-family: sans-serif, Verdana;
  font-size: smaller;
  padding: 3px;
  pointer-events: none;
  z-index: 999;
}

ul.recent-post-details li {
  padding-left: 3px !important;
}
