.costum-input {
  margin-bottom: 10px;
}

.costum-input-input {
  border-radius: 5px;
  padding: 5.5px 8px;
  border: 1px solid black;
  box-shadow: none;
}

.costum-input-input:focus {
  box-shadow: none;
  border: 1px solid #9da5b1;
}

.wrapper .body .container-lg {
  max-width: 100%;
}

.sidebar-nav .nav-link.active {
  background: #102d4e;
}

.swal-button {
  box-shadow: none;
  outline: none;
}

.swal-modal .swal-footer {
  text-align: center;
}

.swal-modal
  .swal-footer
  .swal-button-container
  .swal-button.swal-button--save:hover,
.swal-modal
  .swal-footer
  .swal-button-container
  .swal-button.swal-button--save:active {
  background-color: none !important;
}

.swal-modal
  .swal-footer
  .swal-button-container
  .swal-button.swal-button--discard:hover,
.swal-modal
  .swal-footer
  .swal-button-container
  .swal-button.swal-button--discard:active {
  background-color: none !important;
}

.swal-button--save {
  background-color: #102d4e;
  color: #fff;
  border: 1px #102d4e solid;
  box-shadow: none;
}

.swal-modal
  .swal-footer
  .swal-button-container
  .swal-button.swal-button--save:hover,
.swal-modal
  .swal-footer
  .swal-button-container
  .swal-button.swal-button--save:focus,
.swal-modal
  .swal-footer
  .swal-button-container
  .swal-button.swal-button--save:active {
  background-color: #ffffff;
  color: #102d4e;
  box-shadow: none;
}

.swal-button--discard {
  background-color: #fff;
  color: #c13d3d;
  border: 1px solid #c13d3d;
  box-shadow: none;
}

.swal-modal
  .swal-footer
  .swal-button-container
  .swal-button.swal-button--discard:hover,
.swal-modal
  .swal-footer
  .swal-button-container
  .swal-button.swal-button--discard:focus,
.swal-modal
  .swal-footer
  .swal-button-container
  .swal-button.swal-button--discard:active {
  background-color: #c13d3d;
  color: #ffffff;
  box-shadow: none;
}

.arrow-navigation {
  /* animation properties */
  animation-name: scroll-nav;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  /* other properties */
  /* width: 300px;
  height: 100px; */
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  opacity: 0.4;
}

@keyframes scroll-nav {
  from {
    top: 0px;
  }
  to {
    top: 30px;
  }
}
